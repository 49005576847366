import React, { useEffect, useState } from 'react';
import { useProductContext } from '../context/ProductContext';
import { useLocation } from 'react-router-dom';
import Products from '../Products/Products';

const SearchResultsPage = () => {
  const { products } = useProductContext();
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();

  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    if (query && query.toLowerCase) {
      const filteredProducts = products.filter(product =>
        Object.values(product).some(field =>
          typeof field === 'string' && field.toLowerCase().includes(query.toLowerCase())
        ) ||
        product.supplierDetails.some(supplier =>
          supplier.supplierName.toLowerCase().includes(query.toLowerCase())
        )
      );
      setSearchResults(filteredProducts);
    } else {
      setSearchResults([]);
    }
  }, [products, query]);

  return (
    <div>
        <h1 className="text-4xl font-extrabold dark:text-white pl-5 pt-10">Visar alla resultat för "{query}"</h1>
      {searchResults.length > 0 ? (
          <Products products={searchResults} />
      ) : (
        <p>No results found</p>
      )}
    </div>
  );
};

export default SearchResultsPage;
