import React, { useState } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Layout from './Layout/Layout';
import ProductDetail from './Products/ProductDetail';
import Header from './Layout/Header';
import Cart from './components/Cart';
import { useProductContext } from './context/ProductContext';
import Start from './pages/StartPage';
import CategoryPage from './pages/CategoryPage';
import Register from './Users/Register';
import Login from './Users/Login';
import UserDetails from './Users/Profile/userDetails';
import OrderHistory from './Users/Profile/OrderHistory';
import OrderDetails from './Users/Profile/Orders/OrderDetails';
import CheckoutPage from './pages/CheckoutPage';
import SupplierPage from './pages/SupplierPage';
import SalesPage from './pages/SalesPage';
import BottomNav from './Navigation/BottomNav';
import OrderSuccess from './pages/OrderSuccess';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResetPassword from './Users/ResetPassword';
import TopHeader from './Layout/TopHeader';
import Kampanj from './components/Kampanj';
import ResellerApplicationForm from './Users/ResellerApplicationForm';
import ShopStart from './pages/StartPage';
import Main from './pages/Main';
import Footer from './Layout/Footer';
import About from './pages/About';
import SearchResultsPage from './pages/SearchResultsPage';
import NotFound from './pages/NoPage';
import ScrollToTop from './components/scrollToTop';
import Klippvaror from './Products/Klippvaror';
const App = () => {
  const { products, discountedProducts } = useProductContext();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleChange = (event, category) => {
    setSelectedCategory(category);
  };

  const handleSupplierChange = (event, supplier) => {
    setSelectedSupplier(supplier);
  };

  const handleClick = (event) => {
    setSelectedCategory(event.target.value);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Header
        handleChange={handleChange}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        handleInputChange={handleInputChange}
      />
      <Sidebar handleChange={handleChange} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Cart />
      <Routes>
        <Route element={<Layout isSidebarOpen={isSidebarOpen} />}>
        
          <Route index path="/" element={<Main />} />
          <Route path="/handla" element={<ShopStart />} />
          <Route path='/om-foretaget' element={<About />} />
          <Route path="/bli-aterforsaljare" element={<ResellerApplicationForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/checkout/order-success" element={<OrderSuccess />} />
          <Route path='/forgot-password' element={<ResetPassword />} />
          <Route path='/reset-password' element={<ResetPasswordPage />} />
          <Route path="/search" element={<SearchResultsPage />} /> {/* Uppdaterad rad */}
          <Route path="/user/:displayName/user-profile" element={<UserDetails />} />
          <Route path="/user/:displayName/order-history" element={<OrderHistory />} />
          <Route path="/user/:displayName/order/:orderId" element={<OrderDetails />} />
          <Route path="/supplier/:selectedSupplier" element={<SupplierPage products={discountedProducts} />} />
          <Route path="/specialcat/:selectedSalesCategory" element={<SalesPage products={products} />} />
          <Route path="/cat/:mainCategorySlug/:subCategorySlug?" element={<CategoryPage />} />
          <Route path="/cat/:mainCategorySlug/product/:inventoryNumber" element={<ProductDetail />} />
          <Route path="/kampanj/:discountCode" element={<Kampanj />} />
          <Route path="/klippvaror" element={<Klippvaror />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <BottomNav toggleSidebar={toggleSidebar} />
      <Footer isSidebarOpen={isSidebarOpen} />
       
    </BrowserRouter>
  );
};

export default App;
