// Header.js
import React from 'react';
import Nav from '../Navigation/Nav';
import TopHeader from './TopHeader';

const Header = ({ handleInputChange, query,toggleCart, showModal, handleChange, toggleSidebar, isSidebarOpen   }) => {
  return (
    <header className='bg-white dark:bg-gray-900 shadow-lg fixed w-full z-50 top-0 start-0 border-b border-gray-200 dark:border-gray-600'>
      <TopHeader />
      <Nav handleInputChange={handleInputChange} handleChange={handleChange} query={query} toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} toggleCart={toggleCart}  showModal={showModal} />
    
    </header>
  );
};

export default Header;
