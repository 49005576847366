import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
const ResellerApplicationForm = () => {
  const [showOtherBusinessType, setShowOtherBusinessType] = useState(false);
  const [hasDifferentDeliveryAddress, setHasDifferentDeliveryAddress] = useState(false);

  const formik = useFormik({
    initialValues: {
      referralSource: '',
      companyName: '',
      businessType: '',
      otherBusinessType: '',
      orgNumber: '',
      address: '',
      zipCode: '',
      city: '',
      deliveryAddress: '',
      deliveryZipCode: '',
      deliveryCity: '',
      otherInfo: '',
      contactPerson: '',
      phoneNumber: '',
      email: '',
      catalogPreference: '', // Uppdaterat för att matcha enkel fält
      marketingConsent: false, // Uppdaterat för att matcha enkel fält
      termsConsent: false,
      privacyConsent: false,
    },
    validationSchema: Yup.object({
      referralSource: Yup.string().required('Obligatoriskt'),
      companyName: Yup.string().required('Obligatoriskt'),
      businessType: Yup.string().required('Obligatoriskt'),
      orgNumber: Yup.string().required('Obligatoriskt'),
      address: Yup.string().required('Obligatoriskt'),
      zipCode: Yup.string().required('Obligatoriskt'),
      city: Yup.string().required('Obligatoriskt'),
      contactPerson: Yup.string().required('Obligatoriskt'),
      phoneNumber: Yup.string().required('Obligatoriskt'),
      email: Yup.string().email('Ogiltig e-postadress').required('Obligatoriskt'),
      catalogPreference: Yup.string().required('Obligatoriskt'), // Uppdaterat
      marketingConsent: Yup.boolean().required('Obligatoriskt'), // Uppdaterat
      termsConsent: Yup.boolean().oneOf([true], 'Du måste godkänna våra allmänna villkor'),
      privacyConsent: Yup.boolean().oneOf([true], 'Du måste godkänna vår integritetspolicy'),
    }),
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        marketingPreferences: {
          catalogPreference: values.catalogPreference,
          marketingConsent: values.marketingConsent,
        },
      };
      console.log('Formatted Form values:', formattedValues); // Lägg till denna rad för att logga värdena
      axios.post('/api/sendcustomerapplication', formattedValues)
        .then(response => {
          alert('Ansökan skickad!');
        })
        .catch(error => {
          console.error('Error sending application:', error.response ? error.response.data : error.message);
          alert('Något gick fel vid inskickningen av ansökan. Försök igen senare.');
        });
    },
  });

  const handleBusinessTypeChange = (event) => {
    formik.handleChange(event);
    setShowOtherBusinessType(event.target.value === 'Övrigt');
  };

  const handleDifferentDeliveryAddressChange = (event) => {
    setHasDifferentDeliveryAddress(event.target.checked);
  };

  return (<div className='container m-auto pt-20 px-10'>
    <h1 className='text-4xl font-extrabold my-2'>Bli återförsäljare ansökningsformulär</h1>
    <form onSubmit={formik.handleSubmit}>
      <h2 className='text-lg font-bold'>Vad kul att ni vill bli återförsäljare hos GastronomiLeverantören!</h2>
      <label className='block text-sm font-medium leading-6 text-gray-900 m-3'>
        Får vi fråga hur ni fick höra talas om oss?
        <select name="referralSource" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.referralSource}>
          <option value="">Välj ett alternativ</option>
          <option value="Sociala Medier (Instagram/Facebook)">Sociala Medier (Instagram/Facebook)</option>
          <option value="Sökning på internet">Sökning på internet</option>
          <option value="Sett våra produkter i butik">Sett våra produkter i butik</option>
          <option value="Sökt efter specifikt varumärke/varumärken">Sökt efter specifikt varumärke/varumärken</option>
          <option value="Inga av ovanstående">Inga av ovanstående</option>
        </select>
        {formik.touched.referralSource && formik.errors.referralSource ? <div>{formik.errors.referralSource}</div> : null}
      </label>
    

      <label className=' m-3 block text-sm font-medium leading-6 text-gray-900'>
        Typ av verksamhet
        <select className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="businessType" onChange={handleBusinessTypeChange} onBlur={formik.handleBlur} value={formik.values.businessType}>
          <option value="">Välj ett alternativ</option>
          <option value="Delikatess">Delikatess</option>
          <option value="Blomsterbutik">Blomsterbutik</option>
          <option value="Te & Kaffehandel">Te & Kaffehandel</option>
          <option value="Present & Inredning">Present & Inredning</option>
          <option value="Presentreklam">Presentreklam</option>
          <option value="Konfektbutik">Konfektbutik</option>
          <option value="Café/Restaurang/Hotellverksamhet">Café/Restaurang/Hotellverksamhet</option>
          <option value="E-handelsbutik">E-handelsbutik</option>
          <option value="Hälsokost">Hälsokost</option>
          <option value="Övrigt">Övrigt</option>
        </select>
        {formik.touched.businessType && formik.errors.businessType ? <div>{formik.errors.businessType}</div> : null}
      </label>

      {showOtherBusinessType && (
        <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
          Vilken typ av verksamhet bedriver ni?
          <input type="text" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="otherBusinessType" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherBusinessType} />
          {formik.touched.otherBusinessType && formik.errors.otherBusinessType ? <div>{formik.errors.otherBusinessType}</div> : null}
        </label>
      )}

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Organisationsnummer
        <input type="text"  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="orgNumber" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.orgNumber} />
        {formik.touched.orgNumber && formik.errors.orgNumber ? <div>{formik.errors.orgNumber}</div> : null}
      </label>
      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Företagsnamn
        <input type="text" autoComplete="organization" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="companyName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.companyName} />
        {formik.touched.companyName && formik.errors.companyName ? <div>{formik.errors.companyName}</div> : null}
      </label>
      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Adress
        <input autoComplete="street-address" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="text" name="address" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} />
        {formik.touched.address && formik.errors.address ? <div>{formik.errors.address}</div> : null}
      </label>

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Postnummer
        <input autoComplete="postal-code" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="text" name="zipCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zipCode} />
        {formik.touched.zipCode && formik.errors.zipCode ? <div>{formik.errors.zipCode}</div> : null}
      </label>

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Ort
        <input autoComplete="address-level2" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="text" name="city" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city} />
        {formik.touched.city && formik.errors.city ? <div>{formik.errors.city}</div> : null}
      </label>

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        
        <input className='h-6 w-6 mx-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600' type="checkbox" name="differentDeliveryAddress" onChange={handleDifferentDeliveryAddressChange} />
        Avvikande leveransadress?
      </label>

      {hasDifferentDeliveryAddress && (
        <>
          <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
            Leveransadress
            <input type="text" autoComplete="shipping street-address" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="deliveryAddress" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.deliveryAddress} />
            {formik.touched.deliveryAddress && formik.errors.deliveryAddress ? <div>{formik.errors.deliveryAddress}</div> : null}
          </label>

          <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
            Leverans Postnummer
            <input type="text" autoComplete="shipping postal-code" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="deliveryZipCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.deliveryZipCode} />
            {formik.touched.deliveryZipCode && formik.errors.deliveryZipCode ? <div>{formik.errors.deliveryZipCode}</div> : null}
          </label>

          <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
            Leverans Ort
            <input autoComplete="shipping address-level2" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="text" name="deliveryCity" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.deliveryCity} />
            {formik.touched.deliveryCity && formik.errors.deliveryCity ? <div>{formik.errors.deliveryCity}</div> : null}
          </label>
        </>
      )}

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Övriga upplysningar
        <textarea name="otherInfo" className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherInfo}></textarea>
      </label>

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Referens/kontaktperson
        <input className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="text" name="contactPerson" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.contactPerson} />
        {formik.touched.contactPerson && formik.errors.contactPerson ? <div>{formik.errors.contactPerson}</div> : null}
      </label>

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        Telefonnummer
        <input className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="text" name="phoneNumber" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber} />
        {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div>{formik.errors.phoneNumber}</div> : null}
      </label>

      <label className='m-3 block text-sm font-medium leading-6 text-gray-900'>
        E-post
        <input className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' type="email" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
        {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
      </label>

  <div className='flex p-8 '>   <fieldset className='m-5 p-8 border-2'>
  <h2 className='text-xl font-bold'>Katalogpreferenser:</h2>
  <p className='text-sm my-5 text-gray-700'> Vi lanserar en produktkatalog åtminstone en gång om året. Här har du möjlighet att ange om du har några specifika preferenser för hur denna katalog skall skickas till dig. </p>
  <label>
    <input 
      className='h-6 w-6 mx-3 border-gray-300 text-indigo-600 focus:ring-indigo-600' 
      type="radio" 
      name="catalogPreference" 
      value="Endast digital" 
      onChange={formik.handleChange} 
      onBlur={formik.handleBlur} 
      checked={formik.values.catalogPreference === 'Endast digital'} 
    />
    Endast digital
  </label>
  <label>
    <input 
      className='h-6 w-6 mx-3 border-gray-300 text-indigo-600 focus:ring-indigo-600' 
      type="radio" 
      name="catalogPreference" 
      value="Fysisk & digital" 
      onChange={formik.handleChange} 
      onBlur={formik.handleBlur} 
      checked={formik.values.catalogPreference === 'Fysisk & digital'} 
    />
    Fysisk & digital
  </label>
  <label>
    <input 
      className='h-6 w-6 mx-3 border-gray-300  text-indigo-600 focus:ring-indigo-600' 
      type="radio" 
      name="catalogPreference" 
      value="Ingen" 
      onChange={formik.handleChange} 
      onBlur={formik.handleBlur} 
      checked={formik.values.catalogPreference === 'Ingen'} 
    />
    Ingen
  </label>
  {formik.touched.catalogPreference && formik.errors.catalogPreference ? <div>{formik.errors.catalogPreference}</div> : null}
</fieldset>

<fieldset className='m-5 border-2 p-8'>
<h2 className='text-xl font-bold'>Katalogpreferenser:</h2>
<p className='text-sm my-5 text-gray-700'> Tillåter ni att vi skickar marknadsföringsrelaterade mail till er? Det kan vara aktuella kampanjer, information om nya leverantörer & produkter med mera. </p>
  <label>
    <input 
      className='h-6 w-6 mx-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600' 
      type="radio" 
      name="marketingConsent" 
      value="JA" 
      onChange={() => formik.setFieldValue("marketingConsent", true)} 
      onBlur={formik.handleBlur} 
      checked={formik.values.marketingConsent === true} 
    />
    JA
  </label>
  <label>
    <input 
      className='h-6 w-6 mx-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600' 
      type="radio" 
      name="marketingConsent" 
      value="NEJ" 
      onChange={() => formik.setFieldValue("marketingConsent", false)} 
      onBlur={formik.handleBlur} 
      checked={formik.values.marketingConsent === false} 
    />
    NEJ
  </label>
</fieldset>
</div>
<div className='flex-col m-8 gap-10'>
<div className='flex-col p-10 border-2'>
  <h2 className='text-xl font-semibold mb-4'>Allmänna Villkor:</h2>
  <input 
    className='h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-5' 
    type="checkbox" 
    name="termsConsent" 
    onChange={formik.handleChange} 
    onBlur={formik.handleBlur} 
    checked={formik.values.termsConsent} 
  />
  Genom att kryssa i denna ruta godkänner du våra allmänna villkor.
  <a className='block text-gray-900 font-semibold ml-11' href="ALLMÄNNA VILLKOR">Läs villkoren här</a>
  {formik.touched.termsConsent && formik.errors.termsConsent ? <div>{formik.errors.termsConsent}</div> : null}
</div>

<div className='flex-col p-10 border-2'>
<h2 className='text-xl font-semibold mb-4'>Integritetspolicy:</h2>
  <input 
    className='h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-5' 
    type="checkbox" 
    name="privacyConsent" 
    onChange={formik.handleChange} 
    onBlur={formik.handleBlur} 
    checked={formik.values.privacyConsent} 
  />
  Genom att kryssa i denna ruta godkänner ni att vi får hantera cookies samt annan användardata enligt vad som beskrivs i vår integritetspolicy.
  <a className='block text-gray-900 font-semibold ml-11' href="INTEGRITETSPOLICY">Läs integritetspolicyn här</a>
  {formik.touched.privacyConsent && formik.errors.privacyConsent ? <div>{formik.errors.privacyConsent}</div> : null}
</div>
</div> 
      <button type="submit" className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-5'>Skicka ansökan</button>
    </form>
    </div>
  );
};

export default ResellerApplicationForm;
