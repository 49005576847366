import React from "react";
import { Link } from "react-router-dom";
import LogoCarousel from "../components/LogoCarousel";
const About = () => {

    return (
    <>
     <nav className="flex p-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to="/om-foretaget">Om Företaget</Link>
                        </div>
                    </li>
                </ol>
            </nav>
            <div className="container mx-auto">
            <h1 className="text-4xl font-extrabold dark:text-white py-5 pl-10">Om GastronomiLeverantören
            </h1>
            <div className="flex flex-center align-center justify-center items-center gap-8 p-10">
            <div className="w-1/2">
            <img src="https://placehold.co/600x400" alt="placeholder" /></div>
            <div className=" w-2/3">
                <h1 className="text-2xl p-2">God smak är den bästa gåvan!</h1>
                <p className="p-2">GastronomiLeverantören är en av Sveriges ledande grossister av delikatessprodukter till specialbutiker och food service. Under våra 29 år i branschen har vi tack vare vår erfarenhet och kunskap byggt upp ett brett och ständigt ökande sortiment av unika premiumprodukter av hög kvalitet. Gärna ekologiskt men framför allt utan tillsatser.</p>
            <p className="p-2">Vår styrka är ett heltäckande sortiment av delikatessprodukter i tider då de flesta företag önskar reducera antalet leverantörer. Vårt sortiment lagerförs i eget lager i Ängelholm, varifrån vi dagligen levererar till våra matintresserade återförsäljare. Våra kunder är allt från delikatessbutiker, caféer, restauranger och inredningsbutiker till blomsterhandeln och frisörer.
            God smak är den bästa gåvan!</p></div>
            </div>
            <h1 className="text-4xl font-extrabold dark:text-white py-5 pl-10 text-center">Våra varumärken:</h1>
<LogoCarousel />
<h1 className="text-4xl font-extrabold dark:text-white pt-10 py-2 pl-8 mx-auto text-center">Lena & Jörgen</h1>
<p className="p-8 mx-auto text-center">Det är vi som äger GastronomiLeverantören. Vi drivs av en stor passion för det vi gör och vi går till jobbet med ett leende. Vår vardag är fylld av god smak och arbetsglädje. Vi omger oss med duktiga och kreativa leverantörer, härlig personal och fantastiska kunder. Vi inspireras av människor och möten och vi blir glada när du hör av dig.</p>
<div className="grid grid-cols-1 md:grid-cols-2 container gap-10 items-center mx-auto text-center align-center justify-center">
    <div className="w-auto mx-auto break-words	">
        <img src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Jörgen Jeppsson</h2>
        <p className="font-bold">CEO/Owner</p>
        <a href="mailto:j.jeppsson@gastronomileverantoren.se">j.jeppsson@gastronomileverantoren.se</a>
    </div>
    <div className="w-auto mx-auto break-words	">
        <img src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Lena Jeppsson</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a>
    </div>
</div>
<h1 className="text-4xl font-extrabold text-center pt-10 pb-10">Vår personal</h1>
<div className="grid grid-cols-2 md:grid-cols-4 items-start gap-10 mx-5 text-center text-wrap">
<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Fredrik Persson</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Viktor Nilsson</h2>
        <p className="font-bold">CFO/Owner</p>
        <a className="text-wrap" href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Kerstin Overgaard</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-fullmx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Yvonne Nilsson</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Tobias Malmberg</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Marcus Siltberg</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Joakim Thelin</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src="https://placehold.co/300x300" alt="placeholder" />
        <h2>Johanna Spetsmark</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a></div></div>
</div>
    </>
    

);
};

export default About;