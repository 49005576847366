import React, { useState, useEffect } from 'react';
import { storage  } from '../firebase/firebase'; // Importera Firebase Storage
import { ref, getDownloadURL } from 'firebase/storage';

const CategoryBanner = ({ mainCategorySlug, subCategorySlug }) => {
    const [bannerUrl, setBannerUrl] = useState(null);
    const placeholderBanner = 'https://fakeimg.pl/1280x560/cccccc/909090?text=Placeholder+1280x560';

    useEffect(() => {
        const fetchBannerUrl = async () => {
            try {
                let bannerSlug = mainCategorySlug; // Använd huvudkategorins slug som standard
                if (subCategorySlug) {
                    bannerSlug = mainCategorySlug; // Använd huvudkategorins slug om det finns en subkategori
                }
                
                const bannerRef = ref(storage, `categoryImage/${bannerSlug}.jpg`);
                const url = await getDownloadURL(bannerRef);
                
                setBannerUrl(url);
            } catch (error) {
                // Om ingen banner hittas, använd standardbilden
                setBannerUrl(placeholderBanner);
            }
        };

        fetchBannerUrl(); // Hämta bannerbildens URL när komponenten renderas
    }, [mainCategorySlug, subCategorySlug]);

    return (
        <div className="banner-container max-h-96 h-auto overflow-hidden flex flex-col justify-center">
            <img src={bannerUrl || placeholderBanner} alt="Banner" className="banner-image w-full " />
        </div>
    );
};

export default CategoryBanner;
