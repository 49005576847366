// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore,loadBundle, doc, getDoc, persistentLocalCache, persistentMultipleTabManager, getDocsFromCache, namedQuery, getFirestore, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, getDownloadURL, ref, } from "firebase/storage";
import { getPerformance } from "firebase/performance";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDit3FP97MCe-oQHU7K51EotB76HPNzX8",
  authDomain: "gastrodatabas.firebaseapp.com",
  projectId: "gastrodatabas",
  storageBucket: "gastrodatabas.appspot.com",
  messagingSenderId: "503453250796",
  appId: "1:503453250796:web:a433d7f0a391a1d05521c9",
  measurementId: "G-DJLT19GFXC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: 10485760  // 10 MB, justera denna siffra beroende på behov
  })
});

export const loadFirestoreBundleAndQuery = async () => {
  try {
    console.log("Loading Firestore bundle and named query...");

    // 1. Hämta metadata-dokumentet för att få lastModifiedDateTime
    const metadataRef = doc(getFirestore(), 'metadata', 'lastUpdate');
    const metadataDoc = await getDoc(metadataRef);
    const lastUpdate = metadataDoc.exists() ? metadataDoc.data().lastModifiedDateTime : null;

    if (!lastUpdate) {
      console.error("No lastModifiedDateTime found in metadata.");
      return [];
    }

    // 2. Kontrollera om bundeln i cachen är äldre än lastModifiedDateTime
    const cachedBundleTimestamp = localStorage.getItem("lastBundleLoadTimestamp");
    
    let shouldReloadBundle = !cachedBundleTimestamp || new Date(cachedBundleTimestamp) < new Date(lastUpdate);

    if (shouldReloadBundle) {
      console.log("Reloading bundle because cache is outdated...");

      // 3. Ladda ner och uppdatera Firestore-bundle från Firebase Storage
      const bundleRef = ref(storage, "bundles/productsBundle.bundle");
      const url = await getDownloadURL(bundleRef);
      const response = await fetch(url);
      const bundleBuffer = await response.arrayBuffer();

      // 4. Ladda om bundle till Firestore
      await loadBundle(getFirestore(), bundleBuffer);

      // 5. Uppdatera lokal cache-tidsstämpel
      localStorage.setItem("lastBundleLoadTimestamp", lastUpdate);
      console.log("Bundle cache updated.");
    } else {
      console.log("Cache is up-to-date, no need to reload the bundle.");
    }

    // 6. Hämta den namngivna query från bundlen
    const query = await namedQuery(getFirestore(), "products-query");

    if (query) {
      // Försök att hämta dokument från cachen först
      let snapshot;
      try {
        snapshot = await getDocsFromCache(query);
        console.log("Data loaded from cache:", snapshot.docs.map(doc => doc.data()));
      } catch (cacheError) {
        console.log("Cache miss, fetching from server...");
        snapshot = await getDocs(query);  // Hämta från servern om cachen misslyckas
      }

      // Returnera produkterna
      const documents = snapshot.docs.map(doc => doc.data());
      return documents;
    } else {
      console.error("Named query 'products-query' not found in the bundle.");
      return [];
    }

  } catch (error) {
    console.error("Error loading Firestore bundle and query:", error);
    throw error;
  }
};



// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);

export const authentication = getAuth(app);
export const storage = getStorage(app);
