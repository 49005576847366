import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/cart';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { sendOrder } from '../auth';

const userDataFromStorage = JSON.parse(sessionStorage.getItem('userData'));

const CheckoutPage = () => {
  const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal, campaignDiscount } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);
  const [userData, setUserData] = useState(userDataFromStorage);
  const [isEditing, setIsEditing] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(userData?.deliveryAddress || {});
  const [noteValue, setNoteValue] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    const storedUserData = JSON.parse(sessionStorage.getItem('userData'));
    if (storedUserData) {
      setUserData(storedUserData);
      setDeliveryAddress(storedUserData.deliveryAddress || {});
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (userData) {
      setIsLoading(false);
      sessionStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [userData]);
  const handleNoteChange = (e) => {
    const additionalInfo = deliveryAddressChanged() ? 'OBS - Avvikande leveransadress\n' : '';
    console.log('Additional Info:', additionalInfo);
    console.log('Note Value:', e.target.value);
    setNoteValue(additionalInfo + e.target.value);
  };

  const deliveryAddressChanged = () => {
    if (deliveryAddress && userData && userData.deliveryAddress) {
      const { addressLine1, addressLine2, addressLine3, postalCode, city } = deliveryAddress;
      const { addressLine1: originalAddressLine1, addressLine2: originalAddressLine2, addressLine3: originalAddressLine3, postalCode: originalPostalCode, city: originalCity } = userData.deliveryAddress;
      const addressChanged = addressLine1 !== originalAddressLine1 || addressLine2 !== originalAddressLine2 || addressLine3 !== originalAddressLine3 || postalCode !== originalPostalCode || city !== originalCity;
      
      console.log('Address changed:', addressChanged);
      return addressChanged;
    }
    return false;
  };

  const handleSaveChanges = () => {
    setOrderData({
      ...orderData,
      soShippingAddress: {
        value: {
          overrideAddress: { value: true },
          addressLine1: { value: deliveryAddress.addressLine1 },
          addressLine2: { value: deliveryAddress.addressLine2 },
          addressLine3: { value: deliveryAddress.addressLine3 },
          postalCode: { value: deliveryAddress.postalCode },
          city: { value: deliveryAddress.city }
        }
      }
    });
    setIsEditing(false);
  };

  const handleSendOrder = async () => {
    try {
      const additionalInfo = deliveryAddressChanged() ? 'OBS - Avvikande leveransadress --' : '';
      const updatedNoteValue = additionalInfo + noteValue;

      const orderData = {
        soShippingAddress: {
          value: {
            overrideAddress: { value: true },
            addressLine1: { value: deliveryAddress.addressLine1 },
            addressLine2: { value: deliveryAddress.addressLine2 },
            addressLine3: { value: deliveryAddress.addressLine3 },
            postalCode: { value: deliveryAddress.postalCode },
            city: { value: deliveryAddress.city }
          }
        },
        lines: cartItems.map(item => {
          const isClipItem = item.lotSerialNumber && item.expirationDate;
   // Strip "-KLIPP-KLIPP" from inventoryNumber if it exists
   const cleanedInventoryNumber = item.inventoryNumber.replace(/-KLIPP-KLIPP$/, '');
 // Set price and unitPrice conditionally
 const priceValue = isClipItem ? item.defaultPrice : item.discountedPrice;
          return {
            salesOrderOperation: { value: "Issue" },
            shipComplete: { value: "BackOrderAllowed" },
            operation: "Insert",
            inventoryNumber: { value: cleanedInventoryNumber },
            warehouse: { value: "1" },
            uom: { value: "ST" },
            quantity: { value: item.quantity },
            itemUrl: { value: item.imageUrl },
            itemDescription: { value: item.description },
            price: { value: priceValue },
            ...(isClipItem && {
              note: {
                value: `KLIPPVARA BF: ${item.expirationDate} BATCH: ${item.lotSerialNumber}`
              },
              unitPrice: {
                value: (priceValue)
              }
            })
          };
        }),
        orderType: { value: "SO" },
        hold: { value: true },
        customer: { value: userData.number },
        description: { value: updatedNoteValue },
      };
console.log('OrderData:', orderData);
    await sendOrder(orderData);
      console.log('order skickad', orderData);
      navigate('order-success', { state: { orderData } });
     clearCart();
      // Navigate('/order-success');
    } catch (error) {
      console.error('Fel vid skicka beställning:', error.message);
    }
  };

  const placeholderImageUrl = 'https://via.placeholder.com/350';


  return (
    <div className="bg-gray-50 dark:bg-gray-800 ">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only dark:text-white">Checkout</h2>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <div>
              <div>
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Dina uppgifter:</h2>
                <div className="mt-4">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">E-postadress:</label>
                  <div className="mt-1">
                    <input
                      type="email"
                      id="email-address"
                      name="email-address"
                      autoComplete="email"
                      disabled
                      value={userData.mainContact.email}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                    />
                  </div>
                  <div>
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">Kundnummer:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="first-name"
                        value={userData.number}
                        name="first-name"
                        autoComplete="given-name"
                        disabled
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-white">Telefonnummer:</label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={userData.mainContact.phone1}
                      autoComplete="tel"
                      disabled
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10 border-t border-gray-200 pt-10">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Leveransadress:</h2>
                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <div className="sm:col-span-2">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-white">Företagsnamn</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="company"
                        value={userData.mainContact.name}
                        disabled
                        id="company"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-white">Referens:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="last-name"
                        name="last-name"
                        autoComplete="family-name"
                        disabled={!isEditing}
                        value={deliveryAddress.addressLine1}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine1: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-white">Adressrad 1:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={deliveryAddress.addressLine1}
                        disabled={!isEditing}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine1: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-white">Adressrad 2:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={deliveryAddress.addressLine2}
                        disabled={!isEditing}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine2: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-white">Adressrad 3:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={deliveryAddress.addressLine3}
                        disabled={!isEditing}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine3: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">Stad:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        autoComplete="address-level2"
                        disabled={!isEditing}
                        value={deliveryAddress.city}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, city: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 dark:text-white">Postnummer:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="postal-code"
                        name="postal-code"
                        autoComplete="postal-code"
                        disabled={!isEditing}
                        value={deliveryAddress.postalCode}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, postalCode: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">Avvikande leveransadress?</h3>
                  <p className="text-sm text-gray-600 mt-1 dark:text-gray-400" >Sätt en avvikande leveransadress för denna beställning. Observera att du endast ändrar leveransadressen för denna order och att detta inte uppdaterar din leveransadress permanent. Vill du göra detta istället ber vi dig göra en anteckning i meddelandefältet nedan eller kontakta oss.</p>
                  <button
                    type="button"
                    onClick={() => {
                      if (isEditing) handleSaveChanges();
                      setIsEditing(!isEditing);
                    }}
                    className="mt-3 w-full bg-green-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  >
                    {isEditing ? 'Spara ändringar' : 'Redigera adress'}
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div>
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Orderöversikt</h2>
                <div className="mt-4 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
                  <h3 className="sr-only">Items in your cart</h3>
                  <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                    {cartItems.map((item) => {
                       const productImageUrl = item.productImages && item.productImages.length > 0 
                       ? item.productImages[0] 
                       : placeholderImageUrl;


                       const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
                       const salesCategory = item.salesCategories && item.salesCategories.length > 0 ? item.salesCategories[0].slug : 'nocat';

                     return(
                      <li key={item.inventoryNumber} className="flex py-6 px-4 sm:px-6">
                        <div className="flex-shrink-0">
                          <img
                            src={productImageUrl}
                            alt={item.imageAlt}
                            className="w-10 rounded-md"
                          />
                        </div>
                        <div className="ml-6 flex-1 flex flex-col justify-between">
                          <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                            
                              <div className="flex justify-between w-full">
                                <h3 className="text-sm w-full">
                                  <Link to={`/cat/${salesCategory}/product/${item.inventoryNumber}`} className="font-medium text-gray-700 dark:text-white hover:text-gray-800">
                                    {item.description}
                                  </Link>
                                </h3>
                              
                             
                              {item.formattedDiscountedPrice ? (
              <p className=" text-sm font-medium text-red-700 dark:text-white">
               {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(item.formattedDiscountedPrice)} kr
              </p>
            ) : (
              <p className="text-sm font-medium">
                {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice)}
              </p>
            )}
                            
                            </div></div>
                          <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                            <div className="relative inline-block text-left">
                              <div className="flex items-center space-x-2">
                                <button
                                  type="button"
                                  className="px-4 py-2 bg-red-400/70 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                  onClick={() => removeFromCart(item)}
                                >
                                  -
                                </button>
                                <span className="text-sm font-medium text-gray-900 dark:text-white">{item.quantity}</span>
                                <button
                                  type="button"
                                  className="px-4 py-2 bg-green-400/70 text-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none focus:bg-green-400/45"
                                  onClick={() => addToCart(item)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                  </ul>
                </div>
              </div>
              <div className="mt-10">
                <label htmlFor="note" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Meddelande till säljare
                </label>
                <div className="mt-1">
                  <textarea
                    id="note"
                    name="note"
                    rows={4}
                    value={noteValue}
                    onChange={handleNoteChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                  />
                </div>
              </div>
              <div className="mt-6"> {campaignDiscount > 0 && (<>
                <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                
                <p>Subtotal:</p>
                <p>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(campaignDiscount+getCartTotal())}</p>
                  </div>
              <div className="flex justify-between text-base font-medium   py-1 font-medium text-red-700 dark:text-white">
                
              <p>Rabatter:</p>
              <p>-{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(campaignDiscount)}</p>
                </div>
                </>
 )}
                   <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                   <p>Totalsumma:</p>
                  <p>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getCartTotal())}</p>
                </div>
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  onClick={handleSendOrder}
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Skicka beställning
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CheckoutPage;