import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CartContext } from '../context/cart';
const OrderSuccess = () => {
    useContext(CartContext);
    const location = useLocation();
    const { orderData } = location.state;
console.log('Order Success loaded');
console.log(orderData);


  // Beräkna subtotal, rabatt och ordertotal
  const calculateTotals = () => {
    let subtotal = 0;
    let totalDiscount = 0;

    orderData.lines.forEach(line => {
      const quantity = line.quantity.value;
      const price = line.price.value;
      const formattedDiscountedPrice = line.unitPrice ? line.unitPrice.value : price;

      subtotal += price * quantity;
      totalDiscount += (price - formattedDiscountedPrice) * quantity;
    });

    const shippingCost = 0; // Ändra om du har dynamiska fraktkostnader
    const orderTotal = subtotal - totalDiscount + shippingCost;

    return { subtotal, totalDiscount, shippingCost, orderTotal };
  };

  const { subtotal, totalDiscount, shippingCost, orderTotal } = calculateTotals();



  // Visa en enkel bekräftelse och en sammanfattning av beställningen
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-base font-medium text-indigo-600">Tack för din order!</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">Den är på väg!</p>
          <p className="mt-2 text-base text-gray-500">Din order behandlas av oss och du får inom kort ett ordererkännande skickat till dig.</p>
        </div>

        <div className="mt-10 border-t border-gray-200">
          <h2 className="sr-only">Din order:</h2>
          <h3 className="sr-only">Varor:</h3>
          {orderData && orderData.lines && orderData.lines.length > 0 ? (
            orderData.lines.map((line, index) => (
              <div key={index} className="flex space-x-6 border-b border-gray-200 py-10">
                <img
                  src={line.itemUrl.value} // Hämta value-attributet från itemUrl
                  alt={line.itemDescription.value} // Hämta value-attributet från itemDescription
                  className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                />
                <div className="flex flex-auto flex-col">
                  <div>
                    <h4 className="font-medium text-gray-900">
                      <a>{line.itemDescription.value}</a> {/* Hämta value-attributet från itemDescription */}
                    </h4>
                  </div>
                  <div className="mt-6 flex flex-1 items-end">
                    <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                      <div className="flex">
                        <dt className="font-medium text-gray-900">Quantity</dt>
                        <dd className="ml-2 text-gray-700">{line.quantity.value}</dd> {/* Hämta value-attributet från quantity */}
                      </div>
                      <div className="flex pl-4 sm:pl-6">
                        <dt className="font-medium text-gray-900">Price</dt>
                        <dd className="ml-2 text-gray-700">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(line.price.value)}</dd> {/* Hämta value-attributet från price */}
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <li>Inga produkter i beställningen</li>
          )}

        <div className="sm:ml-40 sm:pl-6">
          <h3 className="sr-only">Din Beställningsinformation:</h3>

          <h4 className="sr-only">Adress:</h4>
          <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
            <div>
              <dt className="font-medium text-gray-900">Shipping address</dt>
              <dd className="mt-2 text-gray-700">
                <address className="not-italic">
                  <span className="block">{orderData.soShippingAddress.value.addressLine1.value}</span>
                  <span className="block">{orderData.soShippingAddress.value.addressLine2.value}</span>
                  <span className="block">{orderData.soShippingAddress.value.addressLine3.value}</span>
                  <span className="block">{orderData.soShippingAddress.value.postalCode.value}</span>
                  <span className="block">{orderData.soShippingAddress.value.city.value}</span>
                </address>
              </dd>
            </div>
            <div>
             
            </div>
          </dl>

        

          <h3 className="sr-only">Ordersummering:</h3>

<dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
  <div className="flex justify-between">
    <dt className="font-medium text-gray-900">Subtotal</dt>
    <dd className="text-gray-700">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(subtotal)}</dd>
  </div>
  <div className="flex justify-between">
    <dt className="flex font-medium text-gray-900">
      Discount
    </dt>
    <dd className="text-gray-700">-{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDiscount)}</dd>
  </div>
  <div className="flex justify-between">
    <dt className="font-medium text-gray-900">Frakt:</dt>
    <dd className="text-gray-700">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(shippingCost)}</dd>
  </div>
  <div className="flex justify-between">
    <dt className="font-medium text-gray-900">Ordertotal:</dt>
    <dd className="text-gray-900">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(orderTotal)}</dd>
  </div>
</dl>
</div>
</div>
</div>
</div>
);
}

export default OrderSuccess;
