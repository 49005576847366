import { createContext, useState, useEffect, useMemo } from 'react';
import { useProductContext } from './ProductContext';
import { useUserContext } from './userContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  const [isCartOpen, setIsCartOpen] = useState(false);
  const { products, discounts } = useProductContext();
  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      const currentUserCart = localStorage.getItem(`cartItems_${user.uid}`);
      if (currentUserCart) {
        setCartItems(JSON.parse(currentUserCart));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      localStorage.setItem(`cartItems_${user.uid}`, JSON.stringify(cartItems));
    }
  }, [cartItems, user]);

  useEffect(() => {
    if (!user) {
      setCartItems([]);
    }
  }, [user]);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const saveCartToLocalStorage = (items) => {
    localStorage.setItem('cartItems', JSON.stringify(items));
  };

  useEffect(() => {
    saveCartToLocalStorage(cartItems);
  }, [cartItems]);

  const productIncrements = useMemo(() => products.map(product => ({
    inventoryNumber: product.inventoryNumber,
    increment: parseInt(product.attributes.find(attribute => attribute.id === "FRP")?.value) || 1 
  })), [products]);

  const addToCart = (item, lotSerialNumber, expirationDate, quantity, formattedDiscountedPrice) => {
    const prefixedInventoryNumber = lotSerialNumber && expirationDate
      ? `${item.inventoryNumber}-KLIPP`
      : item.inventoryNumber;
  
    const isItemInCart = cartItems.find((cartItem) => cartItem.inventoryNumber === prefixedInventoryNumber);
    const incrementValue = productIncrements.find(product => product.inventoryNumber === item.inventoryNumber)?.increment || 1;
  
    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.inventoryNumber === prefixedInventoryNumber
            ? {
                ...cartItem,
                quantity: cartItem.quantity + incrementValue,
                discountCode: item.discountCode,
                ...(lotSerialNumber && { lotSerialNumber }),
                ...(expirationDate && { expirationDate }),
                ...(formattedDiscountedPrice !== undefined && { discountedPrice: formattedDiscountedPrice })
              }
            : cartItem
        )
      );
    } else {
      setCartItems([
        ...cartItems,
        {
          ...item,
          quantity: incrementValue,
          inventoryNumber: prefixedInventoryNumber,
          discountCode: item.discountCode,
          ...(lotSerialNumber && { lotSerialNumber }),
          ...(expirationDate && { expirationDate }),
          ...(formattedDiscountedPrice !== undefined && { discountedPrice: formattedDiscountedPrice })
        }
      ]);
    }
    saveCartToLocalStorage(cartItems);
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.inventoryNumber === item.inventoryNumber);
  
    if (isItemInCart) {
      const incrementValue = productIncrements.find(product => product.inventoryNumber === item.inventoryNumber)?.increment || 1;
      const newQuantity = isItemInCart.quantity - incrementValue;
  
      if (newQuantity <= 0) {
        setCartItems(cartItems.filter((cartItem) => cartItem.inventoryNumber !== item.inventoryNumber));
      } else {
        setCartItems(
          cartItems.map((cartItem) =>
            cartItem.inventoryNumber === item.inventoryNumber
              ? { ...cartItem, quantity: newQuantity }
              : cartItem
          )
        );
      }
    }
    saveCartToLocalStorage(cartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    saveCartToLocalStorage([]);
  };

  const getShippingStatus = () => {
    const cartTotal = getCartTotal();
    const freeShippingThreshold = 6500;
    const amountRemaining = freeShippingThreshold - cartTotal;
    const percentage = Math.min((cartTotal / freeShippingThreshold) * 100, 100);
    const isFreeShipping = cartTotal >= freeShippingThreshold;
    
    return { cartTotal, amountRemaining, percentage, isFreeShipping };
  };

  const calculateCampaignDiscount = (cartItems, discounts) => {
    let totalDiscount = 0;
    const discountQuantities = {};
  
    cartItems.forEach(item => {
      if (item.discountCodes && item.discountCodes.length > 0) {
        item.discountCodes.forEach(code => {
          const productDiscount = discounts.find(discount => discount.discountCode === code);
          if (productDiscount && productDiscount.discountBreakpoints) {
            if (!discountQuantities[code]) {
              discountQuantities[code] = 0;
            }
            discountQuantities[code] += item.quantity;
          }
        });
      }
    });
  
    for (const discountCode in discountQuantities) {
      const discount = discounts.find(d => d.discountCode === discountCode);
      if (discount) {
        const discountBreakpoint = discount.discountBreakpoints[0];
        if (discountBreakpoint) {
          const quantity = discountQuantities[discountCode];
          const discountAmount = discountBreakpoint.discountAmount;
          const discountQuantity = discountBreakpoint.breakQuantity;
  
          if (quantity >= discountQuantity) {
            const discountMultiplier = Math.floor(quantity / discountQuantity);
            totalDiscount += discountAmount * discountMultiplier;
          }
        }
      }
    }
  
    return totalDiscount;
  };

  const campaignDiscount = useMemo(() => calculateCampaignDiscount(cartItems, discounts), [cartItems, discounts]);

  useEffect(() => {
  //  console.log("Campaign discount in Cart component:", campaignDiscount);
  }, [campaignDiscount]);

  const getCartTotal = () => {
    const total = cartItems.reduce((total, item) => {
      const price = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
      return total + price * item.quantity;
    }, 0);

    return total - campaignDiscount;
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        toggleCart,
        isCartOpen,
        setIsCartOpen,
        getShippingStatus,
        campaignDiscount
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
