import React, { useState } from 'react';
import { authentication } from '../firebase/firebase';
import 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const query = useQuery();
  const history = useNavigate();
  const oobCode = query.get('oobCode'); // oobCode från URL:en

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await authentication.auth().confirmPasswordReset(oobCode, newPassword);
      alert('Password has been reset successfully');
      history.push('/login'); // Omdirigera till inloggningssidan efter framgångsrik återställning
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('Failed to reset password. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        New Password:
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </label>
      <button type="submit">Set New Password</button>
    </form>
  );
};

export default ResetPasswordPage;
