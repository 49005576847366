import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../context/cart";
import { AiOutlineShoppingCart, AiOutlineBulb,  AiOutlineMenuUnfold } from "react-icons/ai";
import Search from "../Layout/Search";
import { useAuth } from "../context/authContext";
import { useProductContext } from "../context/ProductContext";
import GastroLogga from "../imgs/Gastronomileverantoren_logotyp.png";

const Nav = ({ handleInputChange, handleChange, query, toggleSidebar, isSidebarOpen }) => {
  const { searchQuery } = useProductContext();
  const { cartItems, toggleCart } = useContext(CartContext);
  const { currentUser} = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('theme') === 'dark';
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  // eslint-disable-next-line no-unused-vars
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem('theme', !isDarkMode ? 'dark' : 'light');
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (searchQuery) {
        navigate(`/search?query=${searchQuery}`);
      }
    }
  };

  return (
    <nav className={`p-4 ${isSidebarOpen ? 'md:ml-0' : 'md:ml-0'} max-w-screen md:ml-0 flex flex-wrap items-center justify-center gap-2 md-gap-0 md:justify-between mr-md-8 p-4 m-auto`}>
      <AiOutlineMenuUnfold className="hidden md:block cursor-pointer" size="24" onClick={toggleSidebar}>Öppna</AiOutlineMenuUnfold>
      <Link to="/" className="flex align-center space-x-3 rtl:space-x-reverse">
        <img src={GastroLogga} className="h-8" alt="Flowbite Logo" />
        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">GastronomiLeverantören</span>
      </Link>
      
      <Search query={query} handleInputChange={handleInputChange} handleChange={handleChange} handleSearchSubmit={handleSearchSubmit} />
      
      <div className="items-center w-auto justify-between hidden lg:flex md:order-1" id="navbar-sticky">
        <ul className="w-auto items-center flex p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:text-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <button onClick={toggleDarkMode} className="w-auto flex items-center">
              <AiOutlineBulb className="nav-icons" size="24" />
            </button>
          </li>
          {currentUser && (
            <>
              <li>
                <button
                  className="px-4 flex align-center items-center gap-2 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  onClick={toggleCart}
                >
                  <AiOutlineShoppingCart className="nav-icons" size="24" /> ({cartItems.length})
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
