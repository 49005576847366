import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase';
import { setDoc, doc, getDoc } from 'firebase/firestore';

const SupplierEditForm = ({ supplierName, supplierId }) => {
    const [bannerImage, setBannerImage] = useState('');
    const [logoImage, setLogoImage] = useState('');  // Nytt state för logotyp
    const [description, setDescription] = useState('');

    useEffect(() => {
        const fetchSupplierInfo = async () => {
            try {
                const supplierRef = doc(db, 'suppliers', supplierId);
                const supplierDoc = await getDoc(supplierRef);
                if (supplierDoc.exists()) {
                    const data = supplierDoc.data();
                    setBannerImage(data.bannerImage || '');
                    setLogoImage(data.logoImage || '');  // Ladda logotypfältet
                    setDescription(data.description || '');
                }
            } catch (error) {
                console.error('Error fetching supplier info:', error);
            }
        };

        fetchSupplierInfo();
    }, [supplierId]);

    const handleBannerChange = (e) => {
        setBannerImage(e.target.value);
    };

    const handleLogoChange = (e) => {
        setLogoImage(e.target.value);  // Uppdatera logotypfältet
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const supplierRef = doc(db, 'suppliers', supplierId);
            const supplierDoc = await getDoc(supplierRef);
            const existingData = supplierDoc.exists() ? supplierDoc.data() : {};
    
            const updatedData = {
                ...existingData,
                bannerImage: bannerImage || existingData.bannerImage,
                logoImage: logoImage || existingData.logoImage,  // Spara logotypfältet
                description: description || existingData.description,
            };
    
            await setDoc(supplierRef, updatedData, { merge: true });
    
            alert('Information uppdaterad!');
            setBannerImage('');
            setLogoImage('');  // Nollställ fältet efter sparning
            setDescription('');
        } catch (error) {
            console.error('Error updating supplier info:', error);
            alert('Fel vid uppdatering av information');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mt-10 mx-10">
            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="bannerImage">
                    Banner Image URL
                </label>
                <input
                    id="bannerImage"
                    type="text"
                    value={bannerImage}
                    onChange={handleBannerChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="logoImage">
                    Logo Image URL
                </label>
                <input
                    id="logoImage"
                    type="text"
                    value={logoImage}
                    onChange={handleLogoChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="description">
                    Description
                </label>
                <textarea
                    id="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    rows="15" />
            </div>
            <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Uppdatera
            </button>
        </form>
    );
};

export default SupplierEditForm;
