import React, { useEffect, useRef } from "react";
import Category from "./Category/Category";
import SalesCategory from "./Category/SalesCategory";
import Suppliers from "./Suppliers";
import { AiOutlineClose } from "react-icons/ai";
import { useAuth } from "../context/authContext";

const Sidebar = ({ isOpen, toggleSidebar, handleChange }) => {
  const sidebarRef = useRef(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current && 
        !sidebarRef.current.contains(event.target) && 
        event.target.classList.contains('exclude-sidebar')
      ) {
        toggleSidebar();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  // Funktion för att hantera klick på knappar i sidopanelen
  const handleSidebarButtonClick = () => {
    // Kontrollera om det är mobilvy (här antas att mobil är < 768px bred)
    if (window.innerWidth < 768 && isOpen) {
      toggleSidebar(); // Stäng sidopanelen
    }
  };

  return (
    <>
      <aside
        ref={sidebarRef}
        id="separator-sidebar"
        className={`fixed left-0 top-0 md:top-36 md:z-40 z-50 md:w-72 w-full h-screen transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-8 overflow-y-auto bg-gray-50 dark:bg-gray-800 dark:text-white">
          <AiOutlineClose
            className="bg-slate-600 cursor-pointer p-1 m-3 text-white block"
            size={30}
            onClick={toggleSidebar}
          ></AiOutlineClose>

          {currentUser && (
            <SalesCategory handleChange={handleChange} onClick={handleSidebarButtonClick} />
          )}
          <Category handleChange={handleChange} onClick={handleSidebarButtonClick} />
          <Suppliers handleChange={handleChange} onClick={handleSidebarButtonClick} />
          
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
