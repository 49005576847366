import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/cart';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const Card = ({ product, klippvarorData }) => {
  const { mainCategorySlug } = useParams();

  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const frpAttribute = product.attributes.find(attribute => attribute.id === "FRP");
  const viktAttribute = product.attributes.find(attribute => attribute.id === "VIKT");

  const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(product.defaultPrice);
  const frpValue = frpAttribute ? frpAttribute.value : null;
  const viktValue = viktAttribute ? viktAttribute.value : null;
const nyhet = product.salesCategories.find(category => category.slug === 'nyheter');
 
  
  const getColorString = (slug) => {
    switch(slug) {
      case 'kex-brod-kakor':
        return 'bg-kexbrodkakor';
      case 'antipasto':
        return 'bg--antipasto';
        case 'olivolja-balsamico-vinager':
        return 'bg-olivoljabalsamicovinager';
            case 'pasta-ris-soppor':
        return 'bg-pastarissoppor';
            case 'kryddor-salt-smaksattare':
        return 'bg-kryddorsaltsmaksattare';
            case 'saser-dressing-bbq':
        return 'bg-saserdressingbbq';
             case 'marmelad-honung-curd':
        return 'bg-marmeladhonungcurd';
            case 'varma-drycker':
        return 'bg-varmadrycker';
            case 'dryck':
        return 'bg-dryck';
            case 'chips-snacks':
        return 'bg-chipssnacks';
        case 'konfektyr':
            return 'bg-konfektyr';
     
      default:
        return 'bg-defaultcolor';
    }
  };


  const colorString = getColorString(mainCategorySlug);

  const handleNavigation = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
  };

  const getDiscountedPriceToShow = () => {
    if (klippvarorData?.discountedPrice !== undefined) {
        return klippvarorData.discountedPrice;
    } else if (product?.discountedPrice !== undefined) {
        return product.discountedPrice;
    } else {
        return null;
    }
};

const formattedDiscountedPriceToShow = getDiscountedPriceToShow();

const formattedDiscountedPrice = formattedDiscountedPriceToShow
    ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(formattedDiscountedPriceToShow)
    : null;

  const incrementValue = klippvarorData?.quantity || (frpValue ? parseInt(frpValue) : 1); 

  const getStockStatus = (available, incrementValue) => {
      if (available === 0) {
          return <span className="inline-flex items-center rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">Slut i lager</span>;
      } else if (available <= incrementValue * 4) {
          return <span className="inline-flex items-center rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">Fåtal kvar i lager</span>;
      } else {
          return <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">I lager</span>;
      }
  };

  const placeholderImageUrl = 'https://via.placeholder.com/350';
  const imageSrc = product.productThumbnails && product.productThumbnails.length > 0 
      ? product.productThumbnails[0] 
      : product.productImages && product.productImages.length > 0 
          ? product.productImages[0] 
          : placeholderImageUrl;

  useEffect(() => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => setIsLoading(false);
  }, [imageSrc]);

  const getPrefixedInventoryNumber = (product) => {
    const baseInventoryNumber = product.inventoryNumber;
    const isKlippvara = klippvarorData?.lotSerialNumber && klippvarorData?.expirationDate;

    return isKlippvara ? `${baseInventoryNumber}-KLIPP` : baseInventoryNumber;
};

const handleAddToCart = () => {
    const prefixedInventoryNumber = getPrefixedInventoryNumber(product);
    const productToAdd = { 
        ...product, 
        inventoryNumber: prefixedInventoryNumber, 
        discountedPrice: formattedDiscountedPriceToShow 
    };
    addToCart(productToAdd, klippvarorData?.lotSerialNumber, klippvarorData?.expirationDate, incrementValue, formattedDiscountedPriceToShow);
};

const handleRemoveFromCart = (product) => {
    const prefixedInventoryNumber = getPrefixedInventoryNumber(product);
    removeFromCart({ ...product, inventoryNumber: prefixedInventoryNumber });
};


  let supplierName = '';
  if (product.supplierDetails && product.supplierDetails.length > 0) {
      supplierName = product.supplierDetails[0].supplierName;
  }
  const supplierLink = `/supplier/${encodeURIComponent(supplierName)}`;

  return (
      <div id={`product-${product.inventoryNumber}`} className="card p-4 exclude-sidebar border border-gray-200 dark:border-gray-700 m-2 shadow dark:text-white relative" key={product.inventoryNumber}>
           {nyhet && (
                  <span className="inline-flex m-auto items-center rounded-md bg-gray-800 px-2 py-1 text-xs font-medium text-white">
                      NYHET
                  </span>
              )}
          {supplierName && (
              <div className='supplier-name px-5'>
                  <Link to={supplierLink}>
                      {isLoading ? <Skeleton width={100} /> : supplierName}
                  </Link>
              </div>
          )}
          
          <div className={`flex relative rounded-lg h-full dark:bg-gray-800 p-2 flex-col ${isLoading ? 'opacity-50' : ''}`}>
              {product.discountCodes && product.discountCodes.length > 0 && (
                  <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                      KAMPANJ
                  </span>
              )}
         
              {klippvarorData?.lotSerialNumber ? (
                  <span className='absolute z-10 top-0 left-0 m-2 rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700'>BF: {klippvarorData.expirationDate}</span>
              ) : null}
              <Link to={`/cat/${mainCategorySlug}/product/${product.inventoryNumber}`} className='relative mx-1 mt-3 flex h-60 rounded-xl justify-center' onClick={handleNavigation}>
                  {isLoading ? <Skeleton height={240} width={350} /> : <img src={imageSrc} alt={product.description} className="card-img object-contain" />}
              </Link>
             
              <div className="card-details mt-4 px-5 pb-5 dark:text-white">
                  <h3 className="card-title text-md text-slate-900 dark:text-white">
                      {isLoading ? <Skeleton width={200} /> : product.description}
                  </h3>
                  <div className="card-productdetail text-sm text-slate-900 dark:text-white">
                      <p>{isLoading ? <Skeleton width={150} /> : `${product.inventoryNumber} - ${viktValue} - ${frpValue}`}</p>
                      {user && !isLoading ? (
              <>
                  <div className="card-price mt-2 mb-5 flex items-center justify-between lg:flex-row flex-col +">
                      {formattedDiscountedPrice ? (
                          <>
                              <div className='text-2xl font-bold text-red-900 dark:text-white'>{formattedDiscountedPrice}</div>
                              <div className="text-sm text-slate-900 line-through">
                                  {formattedPrice}
                              </div>
                          </>
                      ) : (
                          <div className='text-2xl font-bold text-slate-900 dark:text-white'>{formattedPrice}</div>
                      )}
                  </div>
                  <div className='stock'>{getStockStatus(product.warehouseDetails[0].available, incrementValue)}</div>
              </>
          ) : null}
                      <div className='mt-6 flex justify-between items-center'>
                      {user ? (
    !cartItems.find(item => 
        item.inventoryNumber === getPrefixedInventoryNumber(product)
    ) ? (
        <button
            className={`${colorString}  px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700 dark:bg-gray-700`}
            onClick={() => handleAddToCart(product)}
            disabled={isLoading}
        >
            Lägg i varukorg
        </button>
    ) : (
        <div className="flex gap-4">
            <button
                className="px-4 py-2 bg-red-400/75 text-white text-xs font-bold uppercase rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
                onClick={() => handleRemoveFromCart(product)}
                disabled={isLoading}
            >
                -
            </button>
            <p className='text-gray-600 dark:text-white'>
                {cartItems.find(item => 
                    item.inventoryNumber === getPrefixedInventoryNumber(product)
                ).quantity}
            </p>
            <button
                className="px-4 py-2 bg-green-400/75 text-white text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
                onClick={() => addToCart(product)}
                disabled={isLoading}
            >
                +
            </button>
        </div>
    )
) : (
    <Link
        to={`/cat/${mainCategorySlug}/product/${product.inventoryNumber}`}
        className="px-4 py-2 bg-golden text-white text-xs font-bold uppercase rounded hover:bg-golden/50 focus:outline-none focus:bg-blue-700"
        onClick={handleNavigation}
    >
        Läs mer
    </Link>
)}
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Card;

