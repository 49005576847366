import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useProductContext } from '../../context/ProductContext';
import { AiOutlineArrowRight, AiOutlineRight } from 'react-icons/ai';
function SalesCategory() {
  const navigate = useNavigate();
  const { discounts = [], loading } = useProductContext(); // Default to empty array if undefined

  if (loading) {
    return <p>Loading...</p>;
  }

  const promotionalDiscounts = discounts.filter(discount => discount.promotional && discount.items && discount.items.length > 0 && discount.active === true);
console.log(promotionalDiscounts);
  // Hantera klick på kampanj
  const handleDiscountClick = (discountCode) => {
    navigate(`/kampanj/${discountCode}`);
  };

  return (
    <nav className="flex flex-1 flex-col dark:text-white" aria-label="Sidebar">
      <div className='border-b-2 dark:border-b-gray-600'><button className='sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
        <Link className='sidebar-title m-3 font-bold' to={"/klippvaror"}>Klippvaror </Link><AiOutlineRight size="10"/></button></div>
      <h2 className="sidebar-title m-3 font-bold">Kampanjer</h2>
      <ul className="space-y-2 font-medium">
        {promotionalDiscounts.map((discount, index) => (
          <li key={index} className='border-b-2 dark:border-b-gray-600'>
            <button 
              className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              onClick={() => handleDiscountClick(discount.discountCode)}
            >
              <span className="ms-3">{discount.description}</span>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default SalesCategory;
