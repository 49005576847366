// Layout.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOliveBranch, faBottleDroplet, faGrillHot, faCookie, faCandy, faBowlHot, faMugHot, faHoneyPot,faPepperHot } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid'


import { Link } from 'react-router-dom';

const Main = () => {


  return (
    <div className="">
      <header
  class="relative flex items-center justify-center h-screen max-h-[650px] overflow-hidden"
>
  <div
    class="relative z-30 p-5 text-6xl font-bold text-white "
  >
   God smak är den bästa gåvan!
  </div>
  <video
    autoPlay
    loop
    muted
    playsInline
    class="absolute z-10 w-auto brightness-50 min-w-full min-h-full max-w-none"
  >
    <source
      src="https://storage.googleapis.com/gastrodatabas.appspot.com/market/bg-video.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</header>
        
      <div className="container-full bg-gray-900 py-10">
      <div className='container mx-auto p-8 text-center flex flex-col gap-4 text-white'>
        <h2 className='text-2xl'>Gastronomileverantören är importör av premiumdelikatesser från hela världen.</h2>
        <p className=''>Vi tycker att god smak är de bästa gåvan. 
          Vårt mål är att leverera produkter av högsta kvalitet och med en underbar smak 
          som ger krydda åt livet. Med största omsorg har vi valt ut alla våra 
          produkter för att kunna erbjuda fantastiska smakupplevelser.</p></div>
      </div>
      <div className="container-full py-10"> <div className='container mx-auto p-8 text-center flex flex-col justify-center flex-center gap-4'>
        <h2 className='text-2xl'>Ett brett sortiment av delikatessvaror för alla tillfällen.</h2><p className='container-md'>Med närmre 1000 varor i sortimentet kan vi erbjuda våra kunder delikatesser från hela gourmetbordet. 
          Nedan finner ni bara några av de sorters varor vi tillhandahåller:</p></div>
<div className='container mx-auto flex flex-wrap justify-center text-center  content-center	 justify-items-center gap-6 justify-items-center		'>
  <div className='antipasto flex flex-col  basis-auto w-64  h-32	justify-start content-center text-center gap-4 p-3 self-center  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/antipasto"><FontAwesomeIcon className="text-antipasto" size="xl" icon={faOliveBranch} />
  <h2 className='text-xl text-bold pt-4'>Antipasto</h2>
  </Link> </div>
  <div className='antipasto flex flex-col  basis-auto w-64  h-32	justify-start	 content-center text-center gap-4 p-3 self-center   hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/kex-brod-kakor"><FontAwesomeIcon className="text-kexbrodkakor" size="xl" icon={faCookie} />
  <h2 className='text-xl text-bold pt-4'>Kex, bröd & Kakor</h2>
  </Link> </div>
  <div className='antipasto flex flex-col  basis-auto w-64  h-32	justify-start content-center text-center gap-4 p-3  self-center  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/olivolja-balsamico-vinager"><FontAwesomeIcon className="text-olivoljabalsamicovinager" size="xl" icon={faBottleDroplet} />
  <h2 className='text-xl text-bold pt-4'>Olja, Balsamico & vinäger</h2>
  </Link> </div>
  <div className='antipasto flex flex-col   basis-auto w-64  h-32	justify-start content-center text-center gap-4 p-3  self-center  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/pasta-risotto-soppor"><FontAwesomeIcon size="xl" className="text-pastarissoppor" icon={faBowlHot} />
  <h2 className='text-xl text-bold pt-4'>Pasta, Risotto & Soppor</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64  h-32	justify-start	 content-center text-center gap-4 p-3 self-center  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/kryddor-salt-smaksattare"><FontAwesomeIcon size="xl" className="text-kryddorsaltsmaksattare" icon={faPepperHot} />
  <h2 className='text-xl text-bold pt-4'>Kryddor, salt & Smaksättare</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64  h-32	justify-start	 content-center text-center gap-4 p-3 self-center  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/saser-dressing-bbq"><FontAwesomeIcon size="xl" className="text-saserdressingbbq" icon={faGrillHot} />
  <h2 className='text-xl text-bold pt-4'>Såser, Dressing & BBQ</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64  h-32	justify-start	 content-center text-center gap-4 p-3  self-center hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/antipasto"><FontAwesomeIcon size="xl" className="text-marmeladhonungcurd" icon={faHoneyPot} />
  <h2 className='text-xl text-bold pt-4'>Marmelad, honung & Curd</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64  h-32 justify-startr content-center text-center gap-4 p-3 self-center  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/varma-drycker"><FontAwesomeIcon size="xl" className="text-varmadrycker" icon={faMugHot} />
  <h2 className='text-xl text-bold pt-4'>Varma Drycker</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64  h-32	justify-start	 content-center text-center gap-4 p-3 self-center shrink	grow-0  hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/dryck"><FontAwesomeIcon size="xl" icon={faOliveBranch} className="text-dryck" />
  <h2 className='text-xl text-bold pt-4'>Dryck</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64  h-32	justify-start	content-center text-center gap-4 p-3 self-center grow-0 shrink	 hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/chips-snacks"><FontAwesomeIcon size="xl" className="text-chipssnacks" icon={faOliveBranch} />
  <h2 className='text-xl text-bold pt-4'>Chips & Popcorn</h2>
  </Link> </div>
  <div className='antipasto flex flex-col basis-auto w-64 h-32	justify-start	 content-center text-center gap-4 p-3  self-center shrink	grow-0 hover:text-slate-500 hover:shadow-xl shadow-md'>
 <Link to="cat/konfektyr"><FontAwesomeIcon size="xl" className="text-konfektyr" icon={faCandy} />
  <h2 className='text-xl text-bold pt-4'>Konfektyr</h2>
  </Link> </div>
</div>
<div class="container-full bg-gray-900">
      <div className='container mx-auto p-8 text-center flex flex-col gap-4 text-white'>
        <h2 className='text-2xl'>…Och mycket mer!</h2>
        <p className=''>Som kund hos GastronomiLeverantören har ni tillgång till hela sortimentet – inklusive alla nyheter, kampanjvaror, klippvaror, speciella sortiment & säsongsvaror med mera. 
          Bli återförsäljare idag och ta del av allt vi har att erbjuda!</p>
          <Link className='rounded-md bg-forestgreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-forestgreen-300 focus-visible:outline focus-visible:outline-2 w-1/2 m-auto focus-visible:outline-offset-2 focus-visible:outline-indigo-500' to="/bli-aterforsaljare">
          Bli återförsäljare</Link>
        
       
     </div>
      </div>
        </div>
    </div>
  );
};

export default Main;
