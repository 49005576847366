// Layout.js
import React from 'react';
import Klippvaror from '../Products/Klippvaror';
import Sasong from '../Products/Sasong';
import { useAuth } from "../context/authContext";

import Kampanj from '../components/Kampanj';
import Login from '../Users/Login';
import DiscountNotification from '../components/DiscountNotification';
const ShopStart = () => {
  const { currentUser } = useAuth();

  return (
    <div className="">
      
      {currentUser ? (
        <><DiscountNotification />
          <Klippvaror />
          <Kampanj />
          <Sasong />
        </>
      ) : (<>
        <p className="text-2xl font-bold text-center pt-10 dark:text-white">Vänligen logga in för att se innehållet.</p>
        <Login />
       </>
      )}
    </div>
  );
};

export default ShopStart;
