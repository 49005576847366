import React from 'react';


const NotFound = () => {
    return (
      <div>
        <h1>404</h1>
        <p>Denna sida finns inte.</p>
      </div>
    );
  };
  
  export default NotFound;