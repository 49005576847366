import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { db, storage, loadFirestoreBundleAndQuery } from '../firebase/firebase';
import { getStorage, ref, getDownloadURL, loadBundle } from "firebase/storage";
import { collection, getDocs, query, getDoc, doc, deleteDoc, writeBatch } from 'firebase/firestore';
import { useUserContext } from './userContext';
import { getDiscounts } from '../services/discounts';

const ProductContext = createContext();
export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [klippvaror, setKlippvaror] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [kampanjProducts, setKampanjProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [discounts, setDiscounts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { discountInfo, userData } = useUserContext();
    const [mainCategories, setMainCategories] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
const [discountedProducts, setDiscountedProducts] = useState([]);   
    const stringToSlug = (str) => {
        return str.toLowerCase()
            .replace(/[åä]/g, 'a')
            .replace(/[ö]/g, 'o')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .trim();
    };

    
    const fetchDeltaBundles = async (lastCheckedTime) => {
     
        const deltaBundleRef = ref(storage, `bundles/productsDelta_${lastCheckedTime}.json`);
        const url = await getDownloadURL(deltaBundleRef);
        const response = await fetch(url);
        const updatedProducts = await response.json();
    
        // Cache the updated products in Firestore
        const batch = writeBatch(db);
        updatedProducts.forEach(product => {
            const productRef = doc(db, 'products', product.id);
            batch.set(productRef, product);
        });
        await batch.commit();
    
        return updatedProducts;
    };


    const fetchCategories = async () => {
        const categoriesSnapshot = await getDocs(collection(db, 'categories'));

        const categories = await Promise.all(categoriesSnapshot.docs.map(async (doc) => {
            const mainCategory = {
                id: doc.id,
                ...doc.data(),
                slug: stringToSlug(doc.data().description),
                subCategories: []
            };

            const subCategoriesSnapshot = await getDocs(collection(db, 'categories', doc.id, 'subCategories'));

            let subCategories = subCategoriesSnapshot.docs.map(subDoc => ({
                id: subDoc.id,
                ...subDoc.data(),
                slug: stringToSlug(subDoc.data().description),
                isActive: subDoc.data().isActive || false
            }));

            if (mainCategory.categoryId === "1") {
                subCategories = subCategories.filter(subCategory => subCategory.isActive === true);
            }
            

            mainCategory.subCategories = subCategories;

            return mainCategory;
        }));

        return categories;
    };

    const processUniqueSupplierData = (productsData) => {
        const supplierSet = new Set();
        return productsData.flatMap(product =>
            product.supplierDetails.map(supplier => {
                const supplierKey = `${supplier.supplierName}-${supplier.supplierId}`;
                if (!supplierSet.has(supplierKey)) {
                    supplierSet.add(supplierKey);
                    return {
                        supplierName: supplier.supplierName,
                        supplierId: String(supplier.supplierId)
                    };
                }
                return null;
            })
        ).filter(supplier => supplier !== null);
    };

    

    const fetchSubCategories = async (mainCategoryId) => {
        const subCategoriesSnapshot = await getDocs(collection(db, 'categories', mainCategoryId, 'subCategories'));
        const subCategories = subCategoriesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            slug: stringToSlug(doc.data().description),
            isActive: doc.data().isActive || false
        }));
        return subCategories;
    };

    const processProducts = async () => {
        const productsQuery = collection(db, 'products');
        const productsSnapshot = await getDocs(productsQuery);
    
        return productsSnapshot.docs.map(doc => {
            const product = doc.data();
            const salesCategoriesWithSlug = product.salesCategories.map(category => ({
                ...category,
                slug: stringToSlug(category.description)
            }));
            const supplierDetailsWithSlug = product.supplierDetails.map(supplier => ({
                ...supplier,
                slug: stringToSlug(supplier.supplierName)
            }));
            return {
                ...product,
                salesCategories: salesCategoriesWithSlug,
                supplierDetails: supplierDetailsWithSlug
            };
        }).filter(product => product.status === 'Active');
    };

    const fetchDiscountsAndKampanjProducts = async (productsData) => {
        if (!userData) {
            return productsData;
        }
    
        console.log("Applying discounts for user:", userData);
    
        const discountsList = await getDiscounts();
        setDiscounts(discountsList);
    
        const currentDate = new Date();
    
        // Filtrera ut promotions som är giltiga just nu
        const promotionalDiscounts = discountsList.filter(discount => {
            const effectiveDate = new Date(discount.effectiveDate);
            const expirationDate = new Date(discount.expirationDate);
            return discount.promotional && currentDate >= effectiveDate && currentDate <= expirationDate;
        });
    
        const kampanjProductsList = [];
    
        // Iterera över produkterna och tillämpa rabatter
        const updatedProducts = productsData.map(product => {
            let updatedProduct = { ...product };
            let productUpdated = false;
    
            // Tillämpa kampanjer om produkten är en del av en promotion
            promotionalDiscounts.forEach(discount => {
                if (discount.items && discount.items.some(item => item.itemId === product.inventoryNumber)) {
                    if (!updatedProduct.discountCodes) {
                        updatedProduct.discountCodes = [];
                    }
                    updatedProduct.discountCodes.push(discount.discountCode);
                    if (!kampanjProductsList.includes(updatedProduct)) {
                        kampanjProductsList.push(updatedProduct);
                    }
                    productUpdated = true;
                }
            });
    
            // Tillämpa radrabatt om användaren har en sådan
            if (userData && userData.discount) {
                const discountPercent = userData.discount;
    
                // Undvik att tillämpa radrabatt på klippvaror eller redan kampanjrabatterade varor
                if (!product.isKlippvara && !productUpdated) {
                    updatedProduct.discountedPrice = calculateDiscountedPrice(product.defaultPrice, discountPercent);
                    productUpdated = true;
                }
            }
    
            return updatedProduct;
        });
    
        console.log("Updated Products with Discounts: ", updatedProducts);
        console.log("Kampanj Products: ", kampanjProductsList);
    
        setKampanjProducts(kampanjProductsList);
    
        return updatedProducts; // Returnerar uppdaterade produkter
    };

    const calculateDiscountedPrice = (price, discountPercent) => {
        return price - (price * discountPercent / 100);
    };

    const removeExpiredProduct = async (productId) => {
        try {
            await deleteDoc(doc(db, 'klippvaror', productId));
            console.log(`Product with ID ${productId} has been removed.`);
        } catch (error) {
            console.error('Error removing product: ', error);
        }
    };

    const processKlippvaror = async (productsData) => {
        let klippvarorQuery = collection(db, 'klippvaror');
        const klippvarorSnapshot = await getDocs(klippvarorQuery);
        const klippvarorData = klippvarorSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    
        const combinedData = klippvarorData.map(klippvara => {
            const matchingProduct = productsData.find(product => product.inventoryNumber === klippvara.inventoryItem.number);
    
            if (matchingProduct) {
                return {
                    klippvarorData: klippvara,
                    productData: matchingProduct
                };
            }
    
            return null;
        }).filter(Boolean);
    
        const updatedCombinedDataWithDiscount = [];
    
        for (const item of combinedData) {
            const expirationDate = new Date(item.klippvarorData.expirationDate);
            const today = new Date();
            const diffTime = expirationDate - today;
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
            if (diffDays <= 0) {
                await removeExpiredProduct(item.klippvarorData.id);
            } else {
                let discountPercent = 0;
    
                if (diffDays >= 90) {
                    discountPercent = 30;
                } else if (diffDays >= 60) {
                    discountPercent = 50;
                } else if (diffDays >= 30) {
                    discountPercent = 70;
                } else if (diffDays > 0) {
                    discountPercent = 70;
                }
    
                const discountedPrice = Math.round(item.productData.defaultPrice * (1 - discountPercent / 100));
    
                updatedCombinedDataWithDiscount.push({
                    ...item,
                    klippvarorData: {
                        ...item.klippvarorData,
                        expirationDate: expirationDate.toISOString().split('T')[0],
                        discountedPrice: discountedPrice
                    }
                });
            }
        }
    
        return updatedCombinedDataWithDiscount;
    };




    useEffect(() => {
        const fetchData = async () => {
            console.log("fetchData function is called");
            setLoading(true);
    
            try {
                // 1. Ladda och slå samman initiala bundeln med eventuella delta bundles
                const loadedProducts = await loadFirestoreBundleAndQuery();
                console.log("Merged products data:", loadedProducts);
    
                // 2. Processa produkterna som vanligt
                const processedProducts = await processProducts(loadedProducts);
                console.log("Processed products:", processedProducts);
    
                const uniqueSupplierData = processUniqueSupplierData(processedProducts);
                const klippvarorData = await processKlippvaror(processedProducts);
    
                setProducts(processedProducts);
                setSupplierData(uniqueSupplierData);
                setKlippvaror(klippvarorData);
    
                const fetchedCategories = await fetchCategories();
                const mainCategories = fetchedCategories.map(category => ({
                    id: category.id,
                    description: category.description,
                    slug: category.slug
                }));
    
                const subCategories = fetchedCategories.flatMap(category => category.subCategories);
    
                setMainCategories(mainCategories);
                setSubCategories(subCategories);
    
                if (userData && userData.discount) {
                    const discounted = await fetchDiscountsAndKampanjProducts(processedProducts);
                    setDiscountedProducts(discounted);
                } else {
                    setDiscountedProducts(processedProducts);
                }
    
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [userData]); // Använd endast userData som beroende


    const filterProducts = useCallback((mainCategorySlug, selectedSupplier, subCategorySlug) => {
        console.log('Filtering products with mainCategorySlug:', mainCategorySlug, 'subCategorySlug:', subCategorySlug, 'selectedSupplier:', selectedSupplier);
        console.log('Products before filtering:', products);
        return discountedProducts.filter(product => {
            if (!mainCategorySlug === 'Säsong' && !subCategorySlug === 'Säsong') {
                return !product.salesCategories.some(salesCategory => salesCategory.slug === 'Säsong');
            }
            if (mainCategorySlug && subCategorySlug && selectedSupplier) {
                return product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                    product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug) &&
                    product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else if (mainCategorySlug && subCategorySlug) {
                return product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                    product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
            } else if (mainCategorySlug && selectedSupplier) {
                return product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                    product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else if (subCategorySlug) {
                return product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
            } else if (mainCategorySlug) {
                return product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug);
            } else if (selectedSupplier) {
                return product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else {
                return true;
            }
        });
    }, [discountedProducts]);
    



    // Lägg in denna useEffect för att reaktivt tillämpa rabatter när userData eller products uppdateras
    useEffect(() => {
        if (userData && products.length > 0) {
            fetchDiscountsAndKampanjProducts(products);
        }
    }, [userData]);


    return (
        <ProductContext.Provider value={{ klippvaror, mainCategories, products, subCategories, fetchSubCategories, searchQuery, setSearchQuery, supplierData, filterProducts, kampanjProducts, discounts, loading, discountedProducts }}>
            {children}
        </ProductContext.Provider>
    );
};
