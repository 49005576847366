// Uppdatera Category.js för att hämta och rendera underkategorier
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../../context/ProductContext';
import { AiOutlineArrowRight, AiOutlineRight } from 'react-icons/ai';


const Category = ({handleChange, onClick}) => {
  const navigate = useNavigate();
  const { products, mainCategories, fetchSubCategories } = useProductContext();
  const [selectedMainCategory, setSelectedMainCategory] = useState(null); // För att lagra den valda huvudkategorin
  const [subCategories, setSubCategories] = useState([]); // För att lagra underkategorierna för den valda huvudkategorin


  const handleMainCategoryClick = async (mainCategory) => {
    setSelectedMainCategory(mainCategory);
    const fetchedSubCategories = await fetchSubCategories(mainCategory.id);
    setSubCategories(fetchedSubCategories);
    navigate(`/cat/${mainCategory.slug}`);
    handleChange(); // Kör handleChange om det behövs för andra ändamål
    onClick(); // Stänger sidopanelen i mobilvy
  };

  // Definiera sorteringsordningen för kategorier
  const categorySortOrder = {
    "Antipasto": 2,
    "Kex, bröd & Kakor": 3,
    "Olivolja, balsamico & vinäger": 4,
    "Pasta, ris & soppor": 5,
    "Kryddor, salt & smaksättare": 6,
    "Såser, dressing & BBQ": 7,
    "Marmelad, honung & curd": 8,
    "Varma drycker": 9,
    "Dryck": 10,
    "Chips & snacks": 11,
    "Konfekt": 12,
    "Present": 1,
    // Lägg till fler kategorier och deras sorteringsordning här
  };

  // Sortera huvudkategorierna baserat på categorySortOrder
  const sortedMainCategories = [...mainCategories].sort((a, b) => {
    const orderA = categorySortOrder[a.description] || 999; // Om en kategori inte finns i sorteringsobjektet, ge den en hög siffra
    const orderB = categorySortOrder[b.description] || 999;
    return orderA - orderB;
  });
// Hjälpfunktion för att ta bort bindestreck från slug
const stripHyphens = (slug) => {
  return slug.replace(/-/g, '');
};

  return (
    <nav className="flex flex-1 flex-col dark:text-white" aria-label="Sidebar">
      <h2 className="sidebar-title m-3 font-bold">Kategorier</h2>
      <ul className="space-y-2 font-medium">
        {sortedMainCategories.map((category) => (
          <li key={category.id} className='border-b-2 dark:border-b-gray-600'>
            <button 
              className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              onClick={() => handleMainCategoryClick(category)}
            >
                 <span className={`ms-3 before:border-l-4 before:border-${stripHyphens(category.slug)}  before:mr-3`}>{category.description}</span>
              <AiOutlineRight size="10"/>
            </button>
            {/* Rendera underkategorier om de finns */}
            {selectedMainCategory && selectedMainCategory.id === category.id && subCategories.length > 0 && (
              <ul className="space-y-2 font-medium text-sm ">
                {subCategories.map((subcategory) => (
                  <li key={subcategory.categoryID} className='border-b-2  dark:border-b-gray-600 pl-5'>
                    <button 
                      className="sidebar-label-container flex items-center p-2 text-gray-400 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      onClick={() => navigate(`/cat/${selectedMainCategory.slug}/${subcategory.slug}`)}
                    >
                      <span className="ms-3 ">{subcategory.description}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Category;
