import React, { useEffect, useState } from 'react';
import { useProductContext } from '../context/ProductContext';
import { Link, useNavigate } from 'react-router-dom';

const SearchResults = () => {
    const { products, searchQuery, setSearchQuery } = useProductContext();
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchQuery && searchQuery.toLowerCase) {
            const filteredProducts = products.filter(product =>
                Object.values(product).some(field =>
                    typeof field === 'string' && field.toLowerCase().includes(searchQuery.toLowerCase())
                ) ||
                product.supplierDetails.some(supplier =>
                    supplier.supplierName.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            // Filtrera bort produkter utan giltig salesCategory slug
            const validProducts = filteredProducts.filter(product =>
                product.salesCategories && product.salesCategories.length > 0 && product.salesCategories[0].slug
            );

            const limitedResults = validProducts.slice(0, 20);
            setSearchResults(limitedResults);
        } else {
            setSearchResults([]);
        }
    }, [searchQuery, products]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.search-results') || event.target.closest('a')) {
                setSearchQuery && setSearchQuery('');
            }
        };

        const handleEnterPress = (event) => {
            if (event.key === 'Enter' && searchQuery) {
                navigate(`/search?query=${searchQuery}`);
                setSearchQuery(''); // Stänger rutan vid Enter
            }
        };

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEnterPress);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [setSearchQuery, searchQuery, navigate]);

    const handleLinkClick = () => {
        setSearchQuery(''); // Stänger rutan vid länk-klick
    };

    return (
        <div className='absolute w-full top-20 flex-col flex max-h-72 overflow-scroll search-results'>
            {searchResults.length > 0 && (
                <table className='w-full text-sm text-left font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                    <thead>
                        <tr>
                            <th className='px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Beskrivning</th>
                            <th className='px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Kategori</th>
                            <th className='px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Länk</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(product => (
                            <tr key={product.inventoryNumber} className='border-b border-gray-200 dark:border-gray-600'>
                                <td className='px-4 py-2'><Link to={`/cat/${product.salesCategories[0].slug}/product/${product.inventoryNumber}`} onClick={handleLinkClick}>
                                           {product.description}
                                        </Link></td>
                                <td className='px-4 py-2'>
                                    {product.salesCategories && product.salesCategories.length > 0 && (
                                        <Link to={`/cat/${product.salesCategories[0].slug}`} onClick={handleLinkClick}>
                                            {product.salesCategories[0].description}
                                        </Link>
                                    )}
                                </td>
                                <td className='px-4 py-2'>
                                    {product.salesCategories && product.salesCategories.length > 0 && (
                                        <Link to={`/cat/${product.salesCategories[0].slug}/product/${product.inventoryNumber}`} onClick={handleLinkClick}>
                                            Gå till produkt
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SearchResults;