import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDolly, faCircleCheck, faXmark } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'lastModified', direction: 'desc' }); // Default sortering
  const { displayName } = useParams();

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await axios.get(`/api/get-orders`, {
          params: {
            customerId: displayName,
            pageSize: 70,
            pageIndex: 0,
            orderBy: 'lastModified',
          }
        });

        setOrders(response.data.value);
      } catch (error) {
        console.error('Error fetching order history:', error);
      }
    };

    if (displayName) {
      fetchOrderHistory();
    }
  }, [displayName]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedOrders = [...orders].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('sv-SE');
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'Shipping':
        return (
          <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-sm font-medium text-green-700">
            <FontAwesomeIcon className='mr-2' icon={faDolly} />
            Packas
          </span>
                  );
                  case 'Completed':
                    return (
                      <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-sm font-medium text-green-700">
                        <FontAwesomeIcon icon={faCircleCheck} className="mr-2" />
                        Färdigbehandlad
                      </span>
                    );
                    case 'Cancelled':
                      <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-sm font-medium text-green-700">
                      <FontAwesomeIcon icon={faXmark} className="mr-2" />
                      Avbruten
                    </span>
      case 'Hold':
        return <span className="inline-flex items-center rounded-md bg-yellow-100 px-1.5 py-0.5 text-sm font-medium text-yellow-700">Inväntar hantering</span>;
      case 'Open':
        return <span className="inline-flex items-center rounded-md bg-purple-100 px-1.5 py-0.5 text-sm font-medium text-purple-700">Redo att packas</span>;
      case 'Invoiced':
        return <span className="inline-flex items-center rounded-md bg-indigo-100 px-1.5 py-0.5 text-sm font-medium text-indigo-700">Fakturerad</span>;
      default:
        return status;
    }
  };
  
  


  return (
    <div className=' container mx-auto my-10 pt-10 md:pt-0'>
      <nav className="flex p-5" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
              <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 1 1 1 1v4a1 1 0 0 0 1-1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
              </svg>
              Hem
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
              </svg>
              <Link to={`/user/${displayName}/user-profile`}>Profil</Link>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
              </svg>
              <Link to={`/user/${displayName}/order-history`}>Orderhistorik</Link>
            </div>
          </li>
        </ol>
      </nav>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <h2 className='px-6 text-xl font-bold my-3'>Orderhistorik</h2>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('orderId')}>
                Order ID: {sortConfig.key === 'orderId' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('status')}>
                Status: {sortConfig.key === 'status' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('lastModified')}>
                Senast ändrad: {sortConfig.key === 'lastModified' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('orderQty')}>
                Orderkvantitet: {sortConfig.key === 'orderQty' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('orderTotal')}>
                Order Total: {sortConfig.key === 'orderTotal' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('taxTotal')}>
                Skatt Totalt: {sortConfig.key === 'taxTotal' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
              </th>
              <th scope="col" className="px-6 py-3">
                Se order:
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedOrders.map(order => (
              <tr key={order.orderId} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {order.orderId}
                </th>
                <td className="px-6 py-4"> {translateStatus(order.status)}</td>
                <td className="px-6 py-4">{formatDate(order.lastModified)}</td>
                <td className="px-6 py-4"> {order.orderQty}</td>
                <td className="px-6 py-4">{formatCurrency(order.orderTotal)}</td>
                <td className="px-6 py-4"> {formatCurrency(order.taxTotal)}</td>
                <td className="px-6 py-4">
                  <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to={`/user/${displayName}/order/${order.orderId}`}>Se detaljer</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderHistory;
