import React, { createContext, useContext, useState, useEffect } from 'react';
import { authentication, db } from '../firebase/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [discountInfo, setDiscountInfo] = useState(null);
    const [role, setRole] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    useEffect(() => {
        const unsubscribe = authentication.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                // Användaren är inloggad
                setUser(userAuth);
                const userDataRef = doc(db, 'customers', userAuth.uid);
                const userDataSnapshot = await getDoc(userDataRef);
                if (userDataSnapshot.exists()) {
                    const data = userDataSnapshot.data();
                    const discountPercent = await checkDiscounts(data.number);
                    const updatedData = { ...data, discount: discountPercent };
                    // Spara användaruppgifter i sessionstorage
                    sessionStorage.setItem('userData', JSON.stringify(updatedData));
                    setUserData(updatedData);
                    setRole(updatedData.role);
                    setSupplierId(updatedData.supplierId);
                } else {
                    console.log('Användare finns inte i databasen.');
                }
            } else {
                // Användaren är utloggad
                setUser(null);
                setUserData(null);
                setDiscountInfo(null); // Nollställ rabattinformationen vid utloggning
                setRole(null);
                setSupplierId(null);
                // Ta bort användaruppgifter från sessionstorage vid utloggning
                sessionStorage.removeItem('userData');
            }
        });

        // Läs in användaruppgifter från sessionstorage när komponenten monteras
        const storedUserData = sessionStorage.getItem('userData');
        if (storedUserData) {
            const data = JSON.parse(storedUserData);
            setUserData(data);
            setRole(data.role);
            setSupplierId(data.supplierId);
        }

        // Avregistrera lyssnaren vid komponentavmontering
        return unsubscribe;
    }, []);

    const checkDiscounts = async (userNumber) => {
        const discountsRef = collection(db, 'discounts');
        const discountsSnapshot = await getDocs(discountsRef);
        let discountPercent = 0;

        discountsSnapshot.forEach(doc => {
            const data = doc.data();
            if (data.customers) {
                const customerIds = data.customers.map(customer => customer.customer); // Extrahera kundnumren från arrayen av kunder

                if (customerIds.includes(userNumber)) {
                    console.log('customer exists in customerIds list', userNumber);
                    discountPercent = getDiscountPercentFromCode(data.discountCode);
                    console.log('vad är discountPercent', discountPercent);
                    setDiscountInfo({ discountId: doc.id, description: data.description, discountPercent });
                    return; // Avsluta loopen när en matchning hittas
                }
            }
        });
        return discountPercent;
    };

    const getDiscountPercentFromCode = (discountCode) => {
        switch (discountCode) {
            case "03":
                return 3;
            case "05":
                return 5;
            case "06":
                return 6;
            case "07":
                 return 7;
            case "08":
                return 8;
            case "10":
                return 10;
            case "11":
            return 11;     
            case "12":
                return 12;  
            case "15":
                return 15;  
            case "30":
                return 30;  
                                                              
            case "CUSTOM15":
                return 15;


            // Lägg till fler fall som behövs
            default:
                return 0;
        }
    };

    return (
        <UserContext.Provider value={{ userData, setUserData, user, discountInfo, role, supplierId }}>
            {children}
        </UserContext.Provider>
    );
};
