import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useProductContext } from "../context/ProductContext";
import { CartContext } from "../context/cart";
import ProductBreadcrumb from "./ProductBreadcrumb";
import {Tab } from '@headlessui/react';

import { useUserContext } from "../context/userContext";
import SimilarProducts from "./SimilarProducts"; 

import ProductPDF from "../utils/pdfCreator";
import { PDFDownloadLink } from '@react-pdf/renderer';
import parse, { domToReact } from 'html-react-parser';
import DiscountNotification from "../components/DiscountNotification";

const ProductDetail = () => {
  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { mainCategorySlug, inventoryNumber } = useParams(); 
  const { discountedProducts, klippvaror } = useProductContext(); 
  const { user } = useUserContext();
  const [product, setProduct] = useState(null);
  const [klippvara, setKlippvara] = useState(null);

  useEffect(() => {
    // Hitta den specifika produkten baserat på inventoryNumber
    const foundProduct = discountedProducts.find((product) => product.inventoryNumber === inventoryNumber);
    setProduct(foundProduct);
    
    console.log("Found Product:", foundProduct);
    
    // Om klippvaror existerar, kontrollera om produkten är en klippvara
    if (foundProduct && klippvaror) {
      const matchingKlippvara = klippvaror.find(
        (klipp) => klipp.productData.inventoryNumber === foundProduct.inventoryNumber
      );
      
      console.log("Matching Klippvara:", matchingKlippvara);
      setKlippvara(matchingKlippvara);
    }
  }, [discountedProducts, inventoryNumber, klippvaror]);

  if (!product) {
    return <p>Laddar produkten...</p>; 
  }



  const salesCategory = product.salesCategories && product.salesCategories.length > 0 ? product.salesCategories[0] : null;

  const frpAttribute = product.attributes.find(attribute => attribute.id === "FRP");
  const viktAttribute = product.attributes.find(attribute => attribute.id === "VIKT");
  const itemPrice = product.discountedPrice !== null && product.discountedPrice !== undefined ? product.discountedPrice : product.defaultPrice;
  const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice);

  const frpValue = frpAttribute ? frpAttribute.value : null;
  const viktValue = viktAttribute ? viktAttribute.value : null;
  
  const incrementValue = frpValue ? parseInt(frpValue) : 1;
  const available = product.warehouseDetails[0].available;

  const getStockStatus = (available, incrementValue) => {
    if (available === 0) {
      return "Slut i lager";
    } else if (available <= incrementValue * 4) {
      return "Fåtal kvar i lager";
    } else {
      return "I lager";
    }
  };

  let supplierName = '';

  if (product.supplierDetails && product.supplierDetails.length > 0) {
    supplierName = product.supplierDetails[0].supplierName;
  }

  const placeholderImageUrl = 'https://via.placeholder.com/250'; 

  const handleRemoveFromCart = (product) => {
    removeFromCart(product);
  };

  const stockStatus = getStockStatus(available, incrementValue);
  const productImageUrl = product.productImages && product.productImages.length > 0 ? product.productImages[0] : placeholderImageUrl;
console.log('productImageUrl värdet på produkt:', productImageUrl);
// Initiera variablerna med tomma strängar eller null
let shortDescription = '';
let nutritionalValues = [];
let ingredients = '';
let foundFirstStrong = false;
  if (typeof product.body === 'string') {
  const parsedBody = parse(product.body, {
    replace: (domNode) => {
      if (!foundFirstStrong && domNode.type === 'text') {
        shortDescription += domNode.data;
      } else if (domNode.name === 'strong') {
        foundFirstStrong = true;
        const content = domNode.nextSibling && domNode.nextSibling.data ? domNode.nextSibling.data.trim() : "";
        if (domToReact(domNode.children).toString().includes('Näringsvärde per 100g:')) {
          const kcalIndex = content.indexOf('kcal') + 4; // 4 because we want to include "kcal" and the comma after it
          const firstValue = content.substring(0, kcalIndex).trim();
          const remainingContent = content.substring(kcalIndex).trim();
          const remainingValues = remainingContent.split(/(?<=g),/); // Split on commas followed by a non-digit
          if (remainingValues[0].startsWith(',')) {
            remainingValues[0] = remainingValues[0].substring(1).trim();
          }
          
          nutritionalValues = [
            { label: "Energi:", value: firstValue },
            { label: "Fett:", value: remainingValues[0] },
            { label: "- varav mättat fett:", value: remainingValues[1] },
            { label: "Kolhydrater:", value: remainingValues[2] },
            { label: "- varav sockerarter:", value: remainingValues[3] },
            { label: "Protein:", value: remainingValues[4] },
            { label: "Salt:", value: remainingValues[5] }
          ];
        } else if (domToReact(domNode.children).toString().includes('Ingredienser:')) {
          ingredients = content;
        }
      }
    }
  });
}

  return (
    <div className="bg-white dark:bg-gray-800 dark:text-white  sm:pt-10">
      <DiscountNotification />  
      <div className="max-w-full px-4 sm:px-6 sm:py-8 lg:max-w-full lg:px-8 ">
        <ProductBreadcrumb product={product} mainCategorySlug={mainCategorySlug} />
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 mb-10">
          <Tab.Group as="div" className="flex flex-col-reverse">
            <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {product.productImages ? (
                  product.productImages.map((image, index) => (
                    <Tab key={index} className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                      <img src={image} alt={`Product Image ${index}`} className="card-img h-full w-full object-cover object-center" />
                    </Tab>
                  ))
                ) : (
                  <Tab className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
                    <img src={placeholderImageUrl} alt="Placeholder Image" className="card-img h-full w-full object-cover object-center" />
                  </Tab>
                )}
              </Tab.List>
            </div>
            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full max-h-screen">
              {product.productImages ? (
                product.productImages.map((image, index) => (
                  <Tab.Panel className='max-h-screen h-full object-center justify-center flex ' key={index}>
                    <img src={image} alt={`Product Image ${index}`} className="h-screen w-auto object-contain object-center sm:rounded-l" />
                  </Tab.Panel>
                ))
              ) : (
                <Tab.Panel>
                  <img src={placeholderImageUrl} alt="Placeholder Image" className="h-full w-full object-cover object-center sm:rounded-l" />
                </Tab.Panel>
              )}
            </Tab.Panels>
          </Tab.Group>

          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
        <p>{supplierName}</p>
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{product.description}</h1>
              <div className="mt-3">
                <p>{product.inventoryNumber}- {viktValue} - {frpValue}</p>
                <h3 className="sr-only">Beskrivning:</h3>
               
                {product.body && (
                  <div className="space-y-6 mt-8  text-base text-gray-700 dark:text-white">
                    <span className="short-description space-y-6  text-lg text-gray-700 dark:text-white">{shortDescription}</span>
                   </div>)}
                    {user && (
                <div className="mt-6">
                  <p className="text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">Pris: {formattedPrice}</p>
                  <div className="stock text-md font-semibold text-gray-600 pt-2">Lagerstatus: {stockStatus}</div>
                </div>
              )}
              {user && (
                <>
                <div className='mt-6 flex justify-between items-center'>
                  {!cartItems.find(item => item.inventoryNumber === product.inventoryNumber) ? (
                    <button className='px-4 py-2 bg-gray-800 dark:bg-gray-600 text-white dark:text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700'
                      onClick={() => addToCart(product)}>
                      Lägg i varukorg
                    </button>
                  ) : (
                    <div className="flex gap-4">
                      <button
                        className="px-4 py-2 bg-red-400/75 text-white text-xs font-bold uppercase rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
                        onClick={() => {
                          const cartItem = cartItems.find((item) => item.inventoryNumber === product.inventoryNumber);
                          if (cartItem.quantity === 1) {
                            handleRemoveFromCart(product);
                          } else {
                            removeFromCart(product);
                          }
                        }}>
                        -
                      </button>
                      <p className='text-gray-600'>{cartItems.find(item => item.inventoryNumber === product.inventoryNumber).quantity}</p>
                      <button
                        className="px-4 py-2 bg-green-400/75 text-white text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
                        onClick={() => addToCart(product)}>
                        +
                      </button>
                    </div>
                  )}
                </div>
                
                  </>
              )}
               {/* Lägg till klippvara */}
               {klippvara && (
                <div className="mt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                  <h2 className="text-lg text-red-700 font-bold dark:text-white">Denna vara finns även som klippvara</h2>
                  <p className="text-md font-semibold">Bäst före datum: {klippvara.klippvarorData.expirationDate}</p>
                  <p className="text-lg font-bold">Pris: {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(klippvara.klippvarorData.discountedPrice)}</p>
                  <button
                    className="mt-2 px-4 py-2 bg-gray-800 dark:bg-gray-600 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                    onClick={() => addToCart(product, klippvara.klippvarorData.lotSerialNumber, klippvara.klippvarorData.expirationDate, incrementValue, klippvara.klippvarorData.discountedPrice)}
                  >
                    Lägg i varukorg
                  </button>
                </div>
              )}
               <div className="mt-3 text-sm font-medium text-gray-900 dark:text-white">Ingår i kategori: <Link className="block prose prose-sm  text-gray-500 dark:text-white" to={`/cat/${salesCategory.slug}`}>
                {salesCategory.description}
              </Link></div>

                   {product.body && ( <><div className="ingredients order-2 mt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                      <div className="text-sm font-medium text-gray-900 dark:text-white">Ingredienser:</div> <div className="prose prose-sm mt-4 text-gray-500">{ingredients}</div>
                    </div>
                    <div className="nutritional-value order-3 w-fullmt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                      <div className="text-sm font-medium text-gray-900 dark:text-white"><strong>Näringsvärde per 100g:</strong></div>
                      <div className="w-full prose prose-sm mt-4 text-gray-500 dark:text-white">
                        {nutritionalValues.map((item, index) => (
                          <div className="nutritional-row flex justify-between border-b-2 p-2" key={index}>
                            <span className="label">{item.label}</span><span className="value">{item.value}</span>
                          </div>
                        ))}
                      </div>
                  
                      
                  </div>
                  </>                 )}
              </div>
             
             
         
           
            <section aria-labelledby="policies-heading" className="mt-10">
              <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                <div className="rounded-lg border border-gray-200 bg-gray-50 dark:bg-gray-600 p-6 text-center" >
                  <dt>
                  
                  <PDFDownloadLink className="" document={<ProductPDF product={product} productImageUrl={productImageUrl} />} fileName={`${product.description}.pdf`}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Ladda ned Produktblad')}
      </PDFDownloadLink>
                 </dt>
                  <dd className="mt-1 text-sm text-gray-500"></dd>
                </div>
              </dl>
            </section>
          </div>
        </div>
        {stockStatus === 'I lager' ? (
          <>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Mer produkter från {supplierName}</h1>
            <SimilarProducts currentProduct={product} filterBySupplier />
          </>
        ) : (
          <>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Produkter som liknar {product.description}</h1>
            <SimilarProducts currentProduct={product} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;