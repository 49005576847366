import { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faArrowUpRightFromSquare } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid'
import {
  Disclosure,
  Menu,
  Transition,
} from '@headlessui/react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link,  useNavigate } from 'react-router-dom'
import { useAuth } from '../context/authContext'
import GastroLogga from "../imgs/Gastronomileverantoren_logotyp.png";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TopHeader() {
  const { currentUser, logout } = useAuth();
const navigate = useNavigate();
const handleLogout = () => {
  logout();
  navigate('/');
}


  return (
    <Disclosure as="nav" className="bg-gray-800 test">
      {({ open, close }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-auto"
                    src={GastroLogga}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    <Link to="/" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white" onClick={close}>
                      Start
                    </Link>
                    <Link to="/handla" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white">
                      Handla
                    </Link>
                    <Link
                     to="/recept" 
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                     <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' />
                      Recept
                    </Link>
                    <Link
                     to="/recept" 
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                     <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' />
                      Bildbank
                    </Link>
                    <Link to="/bli-aterforsaljare"
                     
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      Bli återförsäljare
                    </Link>
                    <Link to="/om-foretaget"
                      
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      Om Företaget
                    </Link>
                    <Link to="/kontakt"
                      
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      Kontakt
                    </Link>
                  </div>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    
                  </button>

                  {currentUser ? (
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Användarmeny</span>
              <p className='text-white'><span className="hidden md:block">Inloggad som:</span> <span className='font-bold mr-3'>{currentUser.displayName}</span><FontAwesomeIcon size="xs" icon={faChevronDown} /></p>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )} to={`/user/${currentUser.displayName}/user-profile`}>Profil</Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link onClick={close} className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )} to={`/user/${currentUser.displayName}/order-history`}>Orderhistorik</Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block w-full px-4 py-2 text-left text-sm text-gray-700'
                  )} onClick={handleLogout}>Logga ut</button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <Link to="/login" className="text-white">Logga in</Link>
      )}
                </div>
              </div>
              <div className="-mr-2 flex sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <AiOutlineClose className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <AiOutlineMenu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
  <div className="space-y-1 px-2 pb-3 pt-2">
    <Disclosure.Button
      as={Link}
      to="/handla"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      Handla
    </Disclosure.Button>
    <Disclosure.Button
      as={Link}
      to="/recept"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
     <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' /> Recept
    </Disclosure.Button>
    <Disclosure.Button
      as={Link}
      to="/bildbank"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' />Bildbank
    </Disclosure.Button>
    <Disclosure.Button
      as={Link}
      to="/bli-aterforsaljare"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      Bli återförsäljare
    </Disclosure.Button>
    <Disclosure.Button
      as={Link}
      to="/om-foretaget"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      Om företaget
    </Disclosure.Button>
    <Disclosure.Button
      as={Link}
      to="/kontakt"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      Kontakt
    </Disclosure.Button>
  </div>

  {currentUser ? (
    <div className="border-t border-gray-700 pb-3 pt-4">
      <div className="flex items-center px-5">
        <div className="ml-3">
          <div className="text-base font-medium text-white">{currentUser.displayName}</div>
          <div className="text-sm font-medium text-gray-400">{currentUser.email}</div>
        </div>
      </div>
      <div className="mt-3 space-y-1 px-2">
        <Disclosure.Button
          as={Link}
          to={`/user/${currentUser.displayName}/user-profile`}
          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
        >
          Profil
        </Disclosure.Button>
        <Disclosure.Button
          as={Link}
          to={`/user/${currentUser.displayName}/order-history`}
          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
        >
          Orderhistorik
        </Disclosure.Button>
        <Disclosure.Button
          as="a"
          href="#"
          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
          onClick={() => {
            close();
            handleLogout();
          }}
        >
          Logga ut
        </Disclosure.Button>
      </div>
    </div>
  ) : (
    <Disclosure.Button
      as={Link}
      to="/login"
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
    >
      Logga in
    </Disclosure.Button>
  )}
</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
