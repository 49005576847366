// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GastroLogga from '../imgs/Gastronomileverantoren_logotyp.png';
import { faPhone, faGifts, faEnvelope } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';

const Footer = ({isSidebarOpen}) => {

    return (
       
       <div className={` ${isSidebarOpen ? 'md:ml-72 pl-4' : 'md:ml-0'} container-full bg-gray-900 text-white p-20 z-10 mx-auto justify-center align-center content-center p-12`}>
        <section className='container top-links-section gap-5 inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-auto'>
            <div className='call flex justify-center align-center gap-5 border-b border-slate-700 h-16'>
                <FontAwesomeIcon size="xl" icon={faPhone} /><div className='flex flex-col'>Kontakta vår växel:
                <a className='text-bold' href="tel:0431449130"> 0431 44 91 30</a></div>
            </div>
            <div className='call flex gap-5 border-b border-slate-700 h-16'>
                <FontAwesomeIcon size="xl" icon={faEnvelope} /><div className='flex flex-col'>Maila in din fråga:
                <a className='text-bold' href="mailto:info@gastronomileverantoren.se">info@gastronomileverantoren.se</a></div>
                </div>
            <div className='cta flex border-b  gap-5 border-slate-700 h-16' >
                <Link to="/bli-aterforsaljare"><FontAwesomeIcon size="xl" icon={faGifts} /><div className='flex flex-col'>Ta del av hela sortimentet - Bli återförsäljare idag!</div></Link>
            </div>
            <div className='socials flex  gap-5 border-b border-slate-700 h-16'>
                <FontAwesomeIcon size="xl" icon={faPhone} /><div className='flex flex-col'>Mer från Gastronomileverantören:</div>
            </div>
        </section>
        <section className='main-footer-section container mx-auto my-10 flex flex-wrap justify-between content-center items-center'>
           <div className='flex items-center gap-10'><img src={GastroLogga} alt='gastronomileverantoren-logotype' className='w-16 h-auto'/><span className='flex flex-col'>Metallgatan 21 B, 262 72 Ängelholm
Orgnr: 556493-5780</span></div>
<div className='flex'> - God smak är den bästa gåvan.</div>
        </section>
        </div>
      
    )
}

export default Footer;